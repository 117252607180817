/* tslint:disable */
/* eslint-disable */
/**
 * Game Plan
 * The API description of the almighty Game Plan.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FocusAreaFromJSON, FocusAreaToJSON, } from './FocusArea';
import { SlimCompanyDtoFromJSON, SlimCompanyDtoToJSON, } from './SlimCompanyDto';
/**
 * Check if a given object implements the HomeScreenAnnualPlanCentralizedEffortDto interface.
 */
export function instanceOfHomeScreenAnnualPlanCentralizedEffortDto(value) {
    if (!('focusArea' in value))
        return false;
    if (!('actionsCount' in value))
        return false;
    if (!('companies' in value))
        return false;
    return true;
}
export function HomeScreenAnnualPlanCentralizedEffortDtoFromJSON(json) {
    return HomeScreenAnnualPlanCentralizedEffortDtoFromJSONTyped(json, false);
}
export function HomeScreenAnnualPlanCentralizedEffortDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'focusArea': FocusAreaFromJSON(json['focusArea']),
        'actionsCount': json['actionsCount'],
        'companies': (json['companies'].map(SlimCompanyDtoFromJSON)),
    };
}
export function HomeScreenAnnualPlanCentralizedEffortDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'focusArea': FocusAreaToJSON(value['focusArea']),
        'actionsCount': value['actionsCount'],
        'companies': (value['companies'].map(SlimCompanyDtoToJSON)),
    };
}
