/* tslint:disable */
/* eslint-disable */
/**
 * Game Plan
 * The API description of the almighty Game Plan.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HomeKpiGroupDtoFromJSON, HomeKpiGroupDtoToJSON, } from './HomeKpiGroupDto';
import { PieChartDtoFromJSON, PieChartDtoToJSON, } from './PieChartDto';
/**
 * Check if a given object implements the HomeKpisDto interface.
 */
export function instanceOfHomeKpisDto(value) {
    if (!('totalKpis' in value))
        return false;
    if (!('byType' in value))
        return false;
    if (!('byWixKpi' in value))
        return false;
    if (!('wixKpis' in value))
        return false;
    if (!('primeDirectives' in value))
        return false;
    return true;
}
export function HomeKpisDtoFromJSON(json) {
    return HomeKpisDtoFromJSONTyped(json, false);
}
export function HomeKpisDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'totalKpis': json['totalKpis'],
        'byType': PieChartDtoFromJSON(json['byType']),
        'byWixKpi': PieChartDtoFromJSON(json['byWixKpi']),
        'wixKpis': (json['wixKpis'].map(HomeKpiGroupDtoFromJSON)),
        'primeDirectives': (json['primeDirectives'].map(HomeKpiGroupDtoFromJSON)),
    };
}
export function HomeKpisDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'totalKpis': value['totalKpis'],
        'byType': PieChartDtoToJSON(value['byType']),
        'byWixKpi': PieChartDtoToJSON(value['byWixKpi']),
        'wixKpis': (value['wixKpis'].map(HomeKpiGroupDtoToJSON)),
        'primeDirectives': (value['primeDirectives'].map(HomeKpiGroupDtoToJSON)),
    };
}
