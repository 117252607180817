/* tslint:disable */
/* eslint-disable */
/**
 * Game Plan
 * The API description of the almighty Game Plan.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HomeScreenAnnualPlanCentralizedEffortDtoFromJSON, HomeScreenAnnualPlanCentralizedEffortDtoToJSON, } from './HomeScreenAnnualPlanCentralizedEffortDto';
/**
 * Check if a given object implements the CentralizedEffortsListDto interface.
 */
export function instanceOfCentralizedEffortsListDto(value) {
    if (!('centralizedEfforts' in value))
        return false;
    return true;
}
export function CentralizedEffortsListDtoFromJSON(json) {
    return CentralizedEffortsListDtoFromJSONTyped(json, false);
}
export function CentralizedEffortsListDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'centralizedEfforts': (json['centralizedEfforts'].map(HomeScreenAnnualPlanCentralizedEffortDtoFromJSON)),
    };
}
export function CentralizedEffortsListDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'centralizedEfforts': (value['centralizedEfforts'].map(HomeScreenAnnualPlanCentralizedEffortDtoToJSON)),
    };
}
