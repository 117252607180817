/* tslint:disable */
/* eslint-disable */
/**
 * Game Plan
 * The API description of the almighty Game Plan.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SlimKpiGroupDtoFromJSON, SlimKpiGroupDtoToJSON, } from './SlimKpiGroupDto';
/**
 * @export
 */
export const SlimKpiStatusUnitEnum = {
    Number: 'number',
    Dollars: 'dollars',
    Percentage: 'percentage',
    Boolean: 'boolean'
};
/**
 * Check if a given object implements the SlimKpiStatus interface.
 */
export function instanceOfSlimKpiStatus(value) {
    if (!('id' in value))
        return false;
    if (!('year' in value))
        return false;
    return true;
}
export function SlimKpiStatusFromJSON(json) {
    return SlimKpiStatusFromJSONTyped(json, false);
}
export function SlimKpiStatusFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'actual': json['actual'] == null ? undefined : json['actual'],
        'target': json['target'] == null ? undefined : json['target'],
        'unit': json['unit'] == null ? undefined : json['unit'],
        'year': json['year'],
        'progressToGoalPercentage': json['progressToGoalPercentage'] == null ? undefined : json['progressToGoalPercentage'],
        'kpiGroup': json['kpiGroup'] == null ? undefined : SlimKpiGroupDtoFromJSON(json['kpiGroup']),
    };
}
export function SlimKpiStatusToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'actual': value['actual'],
        'target': value['target'],
        'unit': value['unit'],
        'year': value['year'],
        'progressToGoalPercentage': value['progressToGoalPercentage'],
        'kpiGroup': SlimKpiGroupDtoToJSON(value['kpiGroup']),
    };
}
