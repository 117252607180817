/* tslint:disable */
/* eslint-disable */
/**
 * Game Plan
 * The API description of the almighty Game Plan.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CompanyHealthDtoFromJSON, CompanyHealthDtoToJSON, } from './CompanyHealthDto';
/**
 * Check if a given object implements the GetCompaniesHealthPageResponseDto interface.
 */
export function instanceOfGetCompaniesHealthPageResponseDto(value) {
    if (!('items' in value))
        return false;
    if (!('totalItems' in value))
        return false;
    if (!('itemsPerPage' in value))
        return false;
    if (!('currentPage' in value))
        return false;
    if (!('totalPages' in value))
        return false;
    return true;
}
export function GetCompaniesHealthPageResponseDtoFromJSON(json) {
    return GetCompaniesHealthPageResponseDtoFromJSONTyped(json, false);
}
export function GetCompaniesHealthPageResponseDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'items': (json['items'].map(CompanyHealthDtoFromJSON)),
        'totalItems': json['totalItems'],
        'itemsPerPage': json['itemsPerPage'],
        'currentPage': json['currentPage'],
        'totalPages': json['totalPages'],
    };
}
export function GetCompaniesHealthPageResponseDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'items': (value['items'].map(CompanyHealthDtoToJSON)),
        'totalItems': value['totalItems'],
        'itemsPerPage': value['itemsPerPage'],
        'currentPage': value['currentPage'],
        'totalPages': value['totalPages'],
    };
}
