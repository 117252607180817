/* tslint:disable */
/* eslint-disable */
/**
 * Game Plan
 * The API description of the almighty Game Plan.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PieChartValueDtoFromJSON, PieChartValueDtoToJSON, } from './PieChartValueDto';
/**
 * Check if a given object implements the PieChartDto interface.
 */
export function instanceOfPieChartDto(value) {
    if (!('entityName' in value))
        return false;
    if (!('data' in value))
        return false;
    if (!('propertyName' in value))
        return false;
    return true;
}
export function PieChartDtoFromJSON(json) {
    return PieChartDtoFromJSONTyped(json, false);
}
export function PieChartDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'entityName': json['entityName'],
        'data': (json['data'].map(PieChartValueDtoFromJSON)),
        'propertyName': json['propertyName'],
        'total': json['total'] == null ? undefined : json['total'],
    };
}
export function PieChartDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'entityName': value['entityName'],
        'data': (value['data'].map(PieChartValueDtoToJSON)),
        'propertyName': value['propertyName'],
        'total': value['total'],
    };
}
