/* tslint:disable */
/* eslint-disable */
/**
 * Game Plan
 * The API description of the almighty Game Plan.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const Feedback = {
    Up: 'up',
    Down: 'down',
    Neutral: 'neutral'
};
export function FeedbackFromJSON(json) {
    return FeedbackFromJSONTyped(json, false);
}
export function FeedbackFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function FeedbackToJSON(value) {
    return value;
}
