/* tslint:disable */
/* eslint-disable */
/**
 * Game Plan
 * The API description of the almighty Game Plan.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const Health = {
    High: 'high',
    Medium: 'medium',
    Low: 'low'
};
export function HealthFromJSON(json) {
    return HealthFromJSONTyped(json, false);
}
export function HealthFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HealthToJSON(value) {
    return value;
}
