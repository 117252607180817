/* tslint:disable */
/* eslint-disable */
/**
 * Game Plan
 * The API description of the almighty Game Plan.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PopulationFromJSON, PopulationToJSON, } from './Population';
import { PriorityFromJSON, PriorityToJSON, } from './Priority';
import { SlimCompanyDtoFromJSON, SlimCompanyDtoToJSON, } from './SlimCompanyDto';
import { TimeFrameFromJSON, TimeFrameToJSON, } from './TimeFrame';
/**
 * Check if a given object implements the SlimActionDto interface.
 */
export function instanceOfSlimActionDto(value) {
    if (!('id' in value))
        return false;
    if (!('title' in value))
        return false;
    if (!('description' in value))
        return false;
    if (!('ownerCompany' in value))
        return false;
    return true;
}
export function SlimActionDtoFromJSON(json) {
    return SlimActionDtoFromJSONTyped(json, false);
}
export function SlimActionDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'title': json['title'],
        'description': json['description'],
        'ownerCompany': SlimCompanyDtoFromJSON(json['ownerCompany']),
        'priorities': json['priorities'] == null ? undefined : (json['priorities'].map(PriorityFromJSON)),
        'goals': json['goals'] == null ? undefined : json['goals'],
        'timeFrames': json['timeFrames'] == null ? undefined : (json['timeFrames'].map(TimeFrameFromJSON)),
        'relevantPopulations': json['relevantPopulations'] == null ? undefined : (json['relevantPopulations'].map(PopulationFromJSON)),
        'focusAreas': json['focusAreas'] == null ? undefined : json['focusAreas'],
    };
}
export function SlimActionDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'title': value['title'],
        'description': value['description'],
        'ownerCompany': SlimCompanyDtoToJSON(value['ownerCompany']),
        'priorities': value['priorities'] == null ? undefined : (value['priorities'].map(PriorityToJSON)),
        'goals': value['goals'],
        'timeFrames': value['timeFrames'] == null ? undefined : (value['timeFrames'].map(TimeFrameToJSON)),
        'relevantPopulations': value['relevantPopulations'] == null ? undefined : (value['relevantPopulations'].map(PopulationToJSON)),
        'focusAreas': value['focusAreas'],
    };
}
