/* tslint:disable */
/* eslint-disable */
/**
 * Game Plan
 * The API description of the almighty Game Plan.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CompanyFromJSON, CompanyToJSON, } from './Company';
import { EnrichedKpiGroupDtoFromJSON, EnrichedKpiGroupDtoToJSON, } from './EnrichedKpiGroupDto';
import { SlimFocusAreaDtoFromJSON, SlimFocusAreaDtoToJSON, } from './SlimFocusAreaDto';
/**
 * Check if a given object implements the GetSearchBarResponseDto interface.
 */
export function instanceOfGetSearchBarResponseDto(value) {
    if (!('kpiGroups' in value))
        return false;
    if (!('companies' in value))
        return false;
    if (!('centralizedEfforts' in value))
        return false;
    return true;
}
export function GetSearchBarResponseDtoFromJSON(json) {
    return GetSearchBarResponseDtoFromJSONTyped(json, false);
}
export function GetSearchBarResponseDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'kpiGroups': (json['kpiGroups'].map(EnrichedKpiGroupDtoFromJSON)),
        'companies': (json['companies'].map(CompanyFromJSON)),
        'centralizedEfforts': (json['centralizedEfforts'].map(SlimFocusAreaDtoFromJSON)),
    };
}
export function GetSearchBarResponseDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'kpiGroups': (value['kpiGroups'].map(EnrichedKpiGroupDtoToJSON)),
        'companies': (value['companies'].map(CompanyToJSON)),
        'centralizedEfforts': (value['centralizedEfforts'].map(SlimFocusAreaDtoToJSON)),
    };
}
