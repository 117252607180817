/* tslint:disable */
/* eslint-disable */
/**
 * Game Plan
 * The API description of the almighty Game Plan.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ActionFromJSON, ActionToJSON, } from './Action';
import { CompanyEmployeeFromJSON, CompanyEmployeeToJSON, } from './CompanyEmployee';
import { CompanyStatementFromJSON, CompanyStatementToJSON, } from './CompanyStatement';
import { CompanyTeamStructureFromJSON, CompanyTeamStructureToJSON, } from './CompanyTeamStructure';
import { EmployeeFromJSON, EmployeeToJSON, } from './Employee';
import { KpiFromJSON, KpiToJSON, } from './Kpi';
/**
 * Check if a given object implements the Company interface.
 */
export function instanceOfCompany(value) {
    if (!('id' in value))
        return false;
    if (!('name' in value))
        return false;
    if (!('companyStatement' in value))
        return false;
    if (!('dependentActions' in value))
        return false;
    if (!('employeesCount' in value))
        return false;
    if (!('createdAt' in value))
        return false;
    if (!('updatedAt' in value))
        return false;
    if (!('teamStructure' in value))
        return false;
    if (!('goals' in value))
        return false;
    return true;
}
export function CompanyFromJSON(json) {
    return CompanyFromJSONTyped(json, false);
}
export function CompanyFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'name': json['name'],
        'companyStatement': CompanyStatementFromJSON(json['companyStatement']),
        'companyEmployees': json['companyEmployees'] == null ? undefined : (json['companyEmployees'].map(CompanyEmployeeFromJSON)),
        'ownerEmail': json['ownerEmail'] == null ? undefined : json['ownerEmail'],
        'owner': json['owner'] == null ? undefined : EmployeeFromJSON(json['owner']),
        'actions': json['actions'] == null ? undefined : (json['actions'].map(ActionFromJSON)),
        'dependentActions': (json['dependentActions'] == null ? null : json['dependentActions'].map(ActionFromJSON)),
        'employeesCount': json['employeesCount'],
        'kpis': json['kpis'] == null ? undefined : (json['kpis'].map(KpiFromJSON)),
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
        'teamStructure': CompanyTeamStructureFromJSON(json['teamStructure']),
        'goals': json['goals'],
    };
}
export function CompanyToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'name': value['name'],
        'companyStatement': CompanyStatementToJSON(value['companyStatement']),
        'companyEmployees': value['companyEmployees'] == null ? undefined : (value['companyEmployees'].map(CompanyEmployeeToJSON)),
        'ownerEmail': value['ownerEmail'],
        'owner': EmployeeToJSON(value['owner']),
        'actions': value['actions'] == null ? undefined : (value['actions'].map(ActionToJSON)),
        'dependentActions': (value['dependentActions'] == null ? null : value['dependentActions'].map(ActionToJSON)),
        'employeesCount': value['employeesCount'],
        'kpis': value['kpis'] == null ? undefined : (value['kpis'].map(KpiToJSON)),
        'createdAt': ((value['createdAt']).toISOString()),
        'updatedAt': ((value['updatedAt']).toISOString()),
        'teamStructure': CompanyTeamStructureToJSON(value['teamStructure']),
        'goals': value['goals'],
    };
}
