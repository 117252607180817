/* tslint:disable */
/* eslint-disable */
/**
 * Game Plan
 * The API description of the almighty Game Plan.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the SlimEmployeeDto interface.
 */
export function instanceOfSlimEmployeeDto(value) {
    if (!('email' in value))
        return false;
    if (!('fullName' in value))
        return false;
    return true;
}
export function SlimEmployeeDtoFromJSON(json) {
    return SlimEmployeeDtoFromJSONTyped(json, false);
}
export function SlimEmployeeDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'email': json['email'],
        'fullName': json['fullName'],
        'avatarUrl': json['avatarUrl'] == null ? undefined : json['avatarUrl'],
    };
}
export function SlimEmployeeDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'email': value['email'],
        'fullName': value['fullName'],
        'avatarUrl': value['avatarUrl'],
    };
}
