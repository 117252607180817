/* tslint:disable */
/* eslint-disable */
/**
 * Game Plan
 * The API description of the almighty Game Plan.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { KpiGroupFromJSON, KpiGroupToJSON, } from './KpiGroup';
import { SlimCompanyDtoFromJSON, SlimCompanyDtoToJSON, } from './SlimCompanyDto';
/**
 * Check if a given object implements the EnrichedKpiGroupDto interface.
 */
export function instanceOfEnrichedKpiGroupDto(value) {
    if (!('kpiGroup' in value))
        return false;
    if (!('companies' in value))
        return false;
    if (!('kpisCount' in value))
        return false;
    if (!('actionsCount' in value))
        return false;
    return true;
}
export function EnrichedKpiGroupDtoFromJSON(json) {
    return EnrichedKpiGroupDtoFromJSONTyped(json, false);
}
export function EnrichedKpiGroupDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'kpiGroup': KpiGroupFromJSON(json['kpiGroup']),
        'companies': (json['companies'].map(SlimCompanyDtoFromJSON)),
        'kpisCount': json['kpisCount'],
        'actionsCount': json['actionsCount'],
    };
}
export function EnrichedKpiGroupDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'kpiGroup': KpiGroupToJSON(value['kpiGroup']),
        'companies': (value['companies'].map(SlimCompanyDtoToJSON)),
        'kpisCount': value['kpisCount'],
        'actionsCount': value['actionsCount'],
    };
}
