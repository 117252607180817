/* tslint:disable */
/* eslint-disable */
/**
 * Game Plan
 * The API description of the almighty Game Plan.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the HealthControllerCheck200ResponseInfoValue interface.
 */
export function instanceOfHealthControllerCheck200ResponseInfoValue(value) {
    if (!('status' in value))
        return false;
    return true;
}
export function HealthControllerCheck200ResponseInfoValueFromJSON(json) {
    return HealthControllerCheck200ResponseInfoValueFromJSONTyped(json, false);
}
export function HealthControllerCheck200ResponseInfoValueFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return Object.assign(Object.assign({}, json), { 'status': json['status'] });
}
export function HealthControllerCheck200ResponseInfoValueToJSON(value) {
    if (value == null) {
        return value;
    }
    return Object.assign(Object.assign({}, value), { 'status': value['status'] });
}
