/* tslint:disable */
/* eslint-disable */
/**
 * Game Plan
 * The API description of the almighty Game Plan.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CompanyAndDescriptionDtoFromJSON, CompanyAndDescriptionDtoToJSON, } from './CompanyAndDescriptionDto';
import { KpiGroupFromJSON, KpiGroupToJSON, } from './KpiGroup';
import { SlimActionDtoFromJSON, SlimActionDtoToJSON, } from './SlimActionDto';
import { SlimKpiWithYearStatusDtoFromJSON, SlimKpiWithYearStatusDtoToJSON, } from './SlimKpiWithYearStatusDto';
/**
 * Check if a given object implements the GetKpiGroupPageResponseDto interface.
 */
export function instanceOfGetKpiGroupPageResponseDto(value) {
    if (!('kpiGroup' in value))
        return false;
    if (!('numCompaniesWorkingOnThisGroup' in value))
        return false;
    if (!('numOfImpactingActions' in value))
        return false;
    if (!('relatedKpis' in value))
        return false;
    if (!('impactingActions' in value))
        return false;
    if (!('companiesWorkingOnThis' in value))
        return false;
    return true;
}
export function GetKpiGroupPageResponseDtoFromJSON(json) {
    return GetKpiGroupPageResponseDtoFromJSONTyped(json, false);
}
export function GetKpiGroupPageResponseDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'kpiGroup': KpiGroupFromJSON(json['kpiGroup']),
        'numCompaniesWorkingOnThisGroup': json['numCompaniesWorkingOnThisGroup'],
        'numOfImpactingActions': json['numOfImpactingActions'],
        'relatedKpis': (json['relatedKpis'].map(SlimKpiWithYearStatusDtoFromJSON)),
        'impactingActions': (json['impactingActions'].map(SlimActionDtoFromJSON)),
        'companiesWorkingOnThis': (json['companiesWorkingOnThis'].map(CompanyAndDescriptionDtoFromJSON)),
    };
}
export function GetKpiGroupPageResponseDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'kpiGroup': KpiGroupToJSON(value['kpiGroup']),
        'numCompaniesWorkingOnThisGroup': value['numCompaniesWorkingOnThisGroup'],
        'numOfImpactingActions': value['numOfImpactingActions'],
        'relatedKpis': (value['relatedKpis'].map(SlimKpiWithYearStatusDtoToJSON)),
        'impactingActions': (value['impactingActions'].map(SlimActionDtoToJSON)),
        'companiesWorkingOnThis': (value['companiesWorkingOnThis'].map(CompanyAndDescriptionDtoToJSON)),
    };
}
