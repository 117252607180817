/* tslint:disable */
/* eslint-disable */
/**
 * Game Plan
 * The API description of the almighty Game Plan.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const FocusAreaType = {
    CentralizedEffort: 'centralized-effort',
    PrimeDirective: 'prime-directive'
};
export function FocusAreaTypeFromJSON(json) {
    return FocusAreaTypeFromJSONTyped(json, false);
}
export function FocusAreaTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function FocusAreaTypeToJSON(value) {
    return value;
}
