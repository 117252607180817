/* tslint:disable */
/* eslint-disable */
/**
 * Game Plan
 * The API description of the almighty Game Plan.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const TimeFrame = {
    CurrentMs: 'current-ms',
    NextMs: 'next-ms',
    FutureMs: 'future-ms',
    Q1: 'q1',
    Q2: 'q2',
    Q3: 'q3',
    Q4: 'q4',
    Ongoing: 'ongoing'
};
export function TimeFrameFromJSON(json) {
    return TimeFrameFromJSONTyped(json, false);
}
export function TimeFrameFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function TimeFrameToJSON(value) {
    return value;
}
