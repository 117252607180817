/* tslint:disable */
/* eslint-disable */
/**
 * Game Plan
 * The API description of the almighty Game Plan.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const Population = {
    SelfCreators: 'self-creators',
    Partners: 'partners',
    Enterprise: 'enterprise',
    UpMarket: 'up-market',
    InternalWix: 'internal-wix',
    Developers: 'developers',
    All: 'all',
    Other: 'other'
};
export function PopulationFromJSON(json) {
    return PopulationFromJSONTyped(json, false);
}
export function PopulationFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function PopulationToJSON(value) {
    return value;
}
