/* tslint:disable */
/* eslint-disable */
/**
 * Game Plan
 * The API description of the almighty Game Plan.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AmaMentionedDocumentDtoFromJSON, AmaMentionedDocumentDtoToJSON, } from './AmaMentionedDocumentDto';
import { FeedbackFromJSON, FeedbackToJSON, } from './Feedback';
/**
 * Check if a given object implements the AmaMemory interface.
 */
export function instanceOfAmaMemory(value) {
    if (!('question' in value))
        return false;
    if (!('answer' in value))
        return false;
    if (!('createdAt' in value))
        return false;
    if (!('id' in value))
        return false;
    if (!('userId' in value))
        return false;
    if (!('embedding' in value))
        return false;
    if (!('sessionId' in value))
        return false;
    return true;
}
export function AmaMemoryFromJSON(json) {
    return AmaMemoryFromJSONTyped(json, false);
}
export function AmaMemoryFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'question': json['question'],
        'answer': json['answer'],
        'mentionedDocuments': json['mentionedDocuments'] == null ? undefined : (json['mentionedDocuments'].map(AmaMentionedDocumentDtoFromJSON)),
        'feedback': json['feedback'] == null ? undefined : FeedbackFromJSON(json['feedback']),
        'createdAt': (new Date(json['createdAt'])),
        'id': json['id'],
        'userId': json['userId'],
        'embedding': json['embedding'],
        'sessionId': json['sessionId'],
    };
}
export function AmaMemoryToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'question': value['question'],
        'answer': value['answer'],
        'mentionedDocuments': value['mentionedDocuments'] == null ? undefined : (value['mentionedDocuments'].map(AmaMentionedDocumentDtoToJSON)),
        'feedback': FeedbackToJSON(value['feedback']),
        'createdAt': ((value['createdAt']).toISOString()),
        'id': value['id'],
        'userId': value['userId'],
        'embedding': value['embedding'],
        'sessionId': value['sessionId'],
    };
}
