/* tslint:disable */
/* eslint-disable */
/**
 * Game Plan
 * The API description of the almighty Game Plan.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SlimCompanyDtoFromJSON, SlimCompanyDtoToJSON, } from './SlimCompanyDto';
import { SlimKpiGroupDtoFromJSON, SlimKpiGroupDtoToJSON, } from './SlimKpiGroupDto';
import { SlimKpiStatusFromJSON, SlimKpiStatusToJSON, } from './SlimKpiStatus';
/**
 * Check if a given object implements the SlimKpiWithYearStatusDto interface.
 */
export function instanceOfSlimKpiWithYearStatusDto(value) {
    if (!('id' in value))
        return false;
    if (!('name' in value))
        return false;
    return true;
}
export function SlimKpiWithYearStatusDtoFromJSON(json) {
    return SlimKpiWithYearStatusDtoFromJSONTyped(json, false);
}
export function SlimKpiWithYearStatusDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'name': json['name'],
        'kpiGroup': json['kpiGroup'] == null ? undefined : SlimKpiGroupDtoFromJSON(json['kpiGroup']),
        'currentYearStatus': json['currentYearStatus'] == null ? undefined : SlimKpiStatusFromJSON(json['currentYearStatus']),
        'previousYearStatus': json['previousYearStatus'] == null ? undefined : SlimKpiStatusFromJSON(json['previousYearStatus']),
        'owningCompany': json['owningCompany'] == null ? undefined : SlimCompanyDtoFromJSON(json['owningCompany']),
    };
}
export function SlimKpiWithYearStatusDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'name': value['name'],
        'kpiGroup': SlimKpiGroupDtoToJSON(value['kpiGroup']),
        'currentYearStatus': SlimKpiStatusToJSON(value['currentYearStatus']),
        'previousYearStatus': SlimKpiStatusToJSON(value['previousYearStatus']),
        'owningCompany': SlimCompanyDtoToJSON(value['owningCompany']),
    };
}
