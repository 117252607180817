import { Configuration, DefaultApi } from '@thegist-for-wix/api';
import { DependencyList, useCallback, useEffect, useState } from 'react';

import { usePrivateCloudContext } from '@/context/privateCloud';
import { getStorageValue, setStorageValue } from '@/src/localStorage';

export const useBackendHost = () => {
  const { host, isInContext } = usePrivateCloudContext();
  if (!isInContext) {
    throw new Error(
      'Tried to use private cloud context from outside the provider',
    );
  }

  if (process.env.NEXT_PUBLIC_ENV === 'development') {
    return `http://${process.env.NEXT_PUBLIC_BACKEND_ROOT_DOMAIN}`;
  }

  return `https://${process.env.NEXT_PUBLIC_BACKEND_SUBDOMAIN}.${host}.${process.env.NEXT_PUBLIC_BACKEND_ROOT_DOMAIN}`;
};

export const useBackendApi = () => {
  const host = useBackendHost();
  return backendApi(host);
};

let api: DefaultApi;
export const backendApi = (host: string) => {
  if (api) {
    return api;
  }

  const configuration = new Configuration({
    basePath: host,
    credentials: 'include',
  });

  api = new DefaultApi(configuration);
  return api;
};

export const useApi = <T>(
  fetcher: (api: DefaultApi) => Promise<T>,
  deps: DependencyList,
  debounce = 200,
) => {
  const [loading, setLoading] = useState(true);
  const [results, setResults] = useState<T>();
  const [error, setError] = useState<string>();
  const api = useBackendApi();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoized = useCallback(() => fetcher(api), deps);

  useEffect(() => {
    const handler = setTimeout(async () => {
      try {
        setLoading(true);
        setResults(await memoized());
      } catch (e) {
        setError((e as Error).message);
        console.error(e);
      } finally {
        setLoading(false);
      }
    }, debounce);
    return () => clearTimeout(handler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memoized, ...deps]);

  return {
    results,
    loading,
    error,
  };
};

export const useCachedApi = <T>(
  localStorageKey: string,
  fetcher: (api: DefaultApi) => Promise<T>,
  deps: DependencyList,
) => {
  const [loading, setLoading] = useState(true);
  const [results, setResults] = useState<T>();
  const [error, setError] = useState<string>();
  const api = useBackendApi();

  useEffect(() => {
    const cachedValue = getStorageValue<T>(localStorageKey, undefined as T);
    if (cachedValue) {
      setResults(cachedValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoized = useCallback(() => fetcher(api), deps);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const res = await memoized();
        setStorageValue(localStorageKey, res);
        setResults(res);
      } catch (e) {
        setError((e as Error).message);
        console.error(e);
      } finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memoized, ...deps]);

  return { results, loading, error };
};
