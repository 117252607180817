/* tslint:disable */
/* eslint-disable */
/**
 * Game Plan
 * The API description of the almighty Game Plan.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime';
import { ActionFromJSON, ActionToJSON, AmaMemorySessionFromJSON, AmaStreamMessageFromJSON, AskMeAnythingGetMemoriesResponseDtoFromJSON, AuthPingDtoFromJSON, CentralizedEffortsListDtoFromJSON, CompaniesDtoFromJSON, DeleteSessionRequestDtoToJSON, FocusAreaFromJSON, GetActionPageResponseDtoFromJSON, GetAllActionsResponseDtoFromJSON, GetAllKpiGroupsResponseDtoFromJSON, GetAllKpisResponseDtoFromJSON, GetCentralizedEffortPageResponseDtoFromJSON, GetCompaniesHealthPageResponseDtoFromJSON, GetCompanyPageResponseDtoFromJSON, GetExtendedCompanyListResponseFromJSON, GetKpiGroupPageResponseDtoFromJSON, GetKpiPageResponseDtoFromJSON, GetKpiSlimResponseDtoFromJSON, GetSearchBarResponseDtoFromJSON, GetUserAccessStateDtoFromJSON, HealthControllerCheck200ResponseFromJSON, HomeScreenResponseDtoFromJSON, KpiFromJSON, KpiToJSON, PieChartDtoFromJSON, } from '../models/index';
/**
 *
 */
export class DefaultApi extends runtime.BaseAPI {
    /**
     */
    actionsControllerCreateRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['action'] == null) {
                throw new runtime.RequiredError('action', 'Required parameter "action" was null or undefined when calling actionsControllerCreate().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/actions`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ActionToJSON(requestParameters['action']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ActionFromJSON(jsonValue));
        });
    }
    /**
     */
    actionsControllerCreate(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.actionsControllerCreateRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    actionsControllerFindAllRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters['page'] != null) {
                queryParameters['page'] = requestParameters['page'];
            }
            if (requestParameters['limit'] != null) {
                queryParameters['limit'] = requestParameters['limit'];
            }
            if (requestParameters['ownerCompanyId'] != null) {
                queryParameters['ownerCompanyId'] = requestParameters['ownerCompanyId'];
            }
            if (requestParameters['timeFrames'] != null) {
                queryParameters['timeFrames'] = requestParameters['timeFrames'];
            }
            if (requestParameters['dependentTeamIds'] != null) {
                queryParameters['dependentTeamIds'] = requestParameters['dependentTeamIds'];
            }
            if (requestParameters['focusAreas'] != null) {
                queryParameters['focusAreas'] = requestParameters['focusAreas'];
            }
            if (requestParameters['relevantPopulations'] != null) {
                queryParameters['relevantPopulations'] = requestParameters['relevantPopulations'];
            }
            if (requestParameters['matchType'] != null) {
                queryParameters['matchType'] = requestParameters['matchType'];
            }
            if (requestParameters['title'] != null) {
                queryParameters['title'] = requestParameters['title'];
            }
            if (requestParameters['description'] != null) {
                queryParameters['description'] = requestParameters['description'];
            }
            if (requestParameters['kpiId'] != null) {
                queryParameters['kpiId'] = requestParameters['kpiId'];
            }
            if (requestParameters['kpiGroupId'] != null) {
                queryParameters['kpiGroupId'] = requestParameters['kpiGroupId'];
            }
            if (requestParameters['affectedKpis'] != null) {
                queryParameters['affectedKpis'] = requestParameters['affectedKpis'];
            }
            if (requestParameters['hasMentionedImpact'] != null) {
                queryParameters['hasMentionedImpact'] = requestParameters['hasMentionedImpact'];
            }
            if (requestParameters['priorities'] != null) {
                queryParameters['priorities'] = requestParameters['priorities'];
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/actions`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => GetAllActionsResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     */
    actionsControllerFindAll(requestParameters = {}, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.actionsControllerFindAllRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    actionsControllerFindOneRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['id'] == null) {
                throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling actionsControllerFindOne().');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/actions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ActionFromJSON(jsonValue));
        });
    }
    /**
     */
    actionsControllerFindOne(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.actionsControllerFindOneRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    actionsControllerGetActionPageRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['id'] == null) {
                throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling actionsControllerGetActionPage().');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/bff/actions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => GetActionPageResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     */
    actionsControllerGetActionPage(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.actionsControllerGetActionPageRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    actionsControllerGetAnalyticsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['types'] == null) {
                throw new runtime.RequiredError('types', 'Required parameter "types" was null or undefined when calling actionsControllerGetAnalytics().');
            }
            const queryParameters = {};
            if (requestParameters['types'] != null) {
                queryParameters['types'] = requestParameters['types'];
            }
            if (requestParameters['page'] != null) {
                queryParameters['page'] = requestParameters['page'];
            }
            if (requestParameters['limit'] != null) {
                queryParameters['limit'] = requestParameters['limit'];
            }
            if (requestParameters['ownerCompanyId'] != null) {
                queryParameters['ownerCompanyId'] = requestParameters['ownerCompanyId'];
            }
            if (requestParameters['timeFrames'] != null) {
                queryParameters['timeFrames'] = requestParameters['timeFrames'];
            }
            if (requestParameters['dependentTeamIds'] != null) {
                queryParameters['dependentTeamIds'] = requestParameters['dependentTeamIds'];
            }
            if (requestParameters['focusAreas'] != null) {
                queryParameters['focusAreas'] = requestParameters['focusAreas'];
            }
            if (requestParameters['relevantPopulations'] != null) {
                queryParameters['relevantPopulations'] = requestParameters['relevantPopulations'];
            }
            if (requestParameters['matchType'] != null) {
                queryParameters['matchType'] = requestParameters['matchType'];
            }
            if (requestParameters['title'] != null) {
                queryParameters['title'] = requestParameters['title'];
            }
            if (requestParameters['description'] != null) {
                queryParameters['description'] = requestParameters['description'];
            }
            if (requestParameters['kpiId'] != null) {
                queryParameters['kpiId'] = requestParameters['kpiId'];
            }
            if (requestParameters['kpiGroupId'] != null) {
                queryParameters['kpiGroupId'] = requestParameters['kpiGroupId'];
            }
            if (requestParameters['affectedKpis'] != null) {
                queryParameters['affectedKpis'] = requestParameters['affectedKpis'];
            }
            if (requestParameters['hasMentionedImpact'] != null) {
                queryParameters['hasMentionedImpact'] = requestParameters['hasMentionedImpact'];
            }
            if (requestParameters['priorities'] != null) {
                queryParameters['priorities'] = requestParameters['priorities'];
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/bff/actions/analytics`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PieChartDtoFromJSON));
        });
    }
    /**
     */
    actionsControllerGetAnalytics(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.actionsControllerGetAnalyticsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    actionsControllerRemoveRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['id'] == null) {
                throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling actionsControllerRemove().');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/actions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     */
    actionsControllerRemove(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.actionsControllerRemoveRaw(requestParameters, initOverrides);
        });
    }
    /**
     */
    actionsControllerUpdateRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['id'] == null) {
                throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling actionsControllerUpdate().');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/actions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
                method: 'PATCH',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ActionFromJSON(jsonValue));
        });
    }
    /**
     */
    actionsControllerUpdate(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.actionsControllerUpdateRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    actionsExtractorControllerExtractSingleFileRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/actions-extractor`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     */
    actionsExtractorControllerExtractSingleFile(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.actionsExtractorControllerExtractSingleFileRaw(initOverrides);
        });
    }
    /**
     */
    analyticsControllerGetAnalyticsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['types'] == null) {
                throw new runtime.RequiredError('types', 'Required parameter "types" was null or undefined when calling analyticsControllerGetAnalytics().');
            }
            const queryParameters = {};
            if (requestParameters['types'] != null) {
                queryParameters['types'] = requestParameters['types'];
            }
            if (requestParameters['page'] != null) {
                queryParameters['page'] = requestParameters['page'];
            }
            if (requestParameters['limit'] != null) {
                queryParameters['limit'] = requestParameters['limit'];
            }
            if (requestParameters['ownerCompanyId'] != null) {
                queryParameters['ownerCompanyId'] = requestParameters['ownerCompanyId'];
            }
            if (requestParameters['timeFrames'] != null) {
                queryParameters['timeFrames'] = requestParameters['timeFrames'];
            }
            if (requestParameters['dependentTeamIds'] != null) {
                queryParameters['dependentTeamIds'] = requestParameters['dependentTeamIds'];
            }
            if (requestParameters['focusAreas'] != null) {
                queryParameters['focusAreas'] = requestParameters['focusAreas'];
            }
            if (requestParameters['relevantPopulations'] != null) {
                queryParameters['relevantPopulations'] = requestParameters['relevantPopulations'];
            }
            if (requestParameters['matchType'] != null) {
                queryParameters['matchType'] = requestParameters['matchType'];
            }
            if (requestParameters['title'] != null) {
                queryParameters['title'] = requestParameters['title'];
            }
            if (requestParameters['description'] != null) {
                queryParameters['description'] = requestParameters['description'];
            }
            if (requestParameters['kpiId'] != null) {
                queryParameters['kpiId'] = requestParameters['kpiId'];
            }
            if (requestParameters['kpiGroupId'] != null) {
                queryParameters['kpiGroupId'] = requestParameters['kpiGroupId'];
            }
            if (requestParameters['affectedKpis'] != null) {
                queryParameters['affectedKpis'] = requestParameters['affectedKpis'];
            }
            if (requestParameters['hasMentionedImpact'] != null) {
                queryParameters['hasMentionedImpact'] = requestParameters['hasMentionedImpact'];
            }
            if (requestParameters['priorities'] != null) {
                queryParameters['priorities'] = requestParameters['priorities'];
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/analytics/action`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     */
    analyticsControllerGetAnalytics(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.analyticsControllerGetAnalyticsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    appControllerGetHelloRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            if (this.isJsonMime(response.headers.get('content-type'))) {
                return new runtime.JSONApiResponse(response);
            }
            else {
                return new runtime.TextApiResponse(response);
            }
        });
    }
    /**
     */
    appControllerGetHello(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.appControllerGetHelloRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    askMeAnythingControllerAskMeAnythingStreamRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['query'] == null) {
                throw new runtime.RequiredError('query', 'Required parameter "query" was null or undefined when calling askMeAnythingControllerAskMeAnythingStream().');
            }
            const queryParameters = {};
            if (requestParameters['query'] != null) {
                queryParameters['query'] = requestParameters['query'];
            }
            if (requestParameters['sessionId'] != null) {
                queryParameters['sessionId'] = requestParameters['sessionId'];
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/ama/stream`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => AmaStreamMessageFromJSON(jsonValue));
        });
    }
    /**
     */
    askMeAnythingControllerAskMeAnythingStream(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.askMeAnythingControllerAskMeAnythingStreamRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    askMeAnythingControllerDeleteSessionRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['deleteSessionRequestDto'] == null) {
                throw new runtime.RequiredError('deleteSessionRequestDto', 'Required parameter "deleteSessionRequestDto" was null or undefined when calling askMeAnythingControllerDeleteSession().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/ama/session`,
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
                body: DeleteSessionRequestDtoToJSON(requestParameters['deleteSessionRequestDto']),
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     */
    askMeAnythingControllerDeleteSession(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.askMeAnythingControllerDeleteSessionRaw(requestParameters, initOverrides);
        });
    }
    /**
     */
    askMeAnythingControllerGetHistoryRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['sessionId'] == null) {
                throw new runtime.RequiredError('sessionId', 'Required parameter "sessionId" was null or undefined when calling askMeAnythingControllerGetHistory().');
            }
            const queryParameters = {};
            if (requestParameters['sessionId'] != null) {
                queryParameters['sessionId'] = requestParameters['sessionId'];
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/ama/history`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => AskMeAnythingGetMemoriesResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     */
    askMeAnythingControllerGetHistory(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.askMeAnythingControllerGetHistoryRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    askMeAnythingControllerGetUserSessionsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters['take'] != null) {
                queryParameters['take'] = requestParameters['take'];
            }
            if (requestParameters['skip'] != null) {
                queryParameters['skip'] = requestParameters['skip'];
            }
            if (requestParameters['id'] != null) {
                queryParameters['id'] = requestParameters['id'];
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/ama/sessions`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AmaMemorySessionFromJSON));
        });
    }
    /**
     */
    askMeAnythingControllerGetUserSessions(requestParameters = {}, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.askMeAnythingControllerGetUserSessionsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    authControllerForceRefreshRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/auth/force-refresh`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => AuthPingDtoFromJSON(jsonValue));
        });
    }
    /**
     */
    authControllerForceRefresh(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.authControllerForceRefreshRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    authControllerGetAccessStateRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/auth/get-access-state`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => GetUserAccessStateDtoFromJSON(jsonValue));
        });
    }
    /**
     */
    authControllerGetAccessState(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.authControllerGetAccessStateRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    authControllerLoginRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/auth/login`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     */
    authControllerLogin(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.authControllerLoginRaw(initOverrides);
        });
    }
    /**
     */
    authControllerPingRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/auth/ping`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => AuthPingDtoFromJSON(jsonValue));
        });
    }
    /**
     */
    authControllerPing(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.authControllerPingRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    authControllerRequestAccessRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/auth/request-access`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     */
    authControllerRequestAccess(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.authControllerRequestAccessRaw(initOverrides);
        });
    }
    /**
     */
    bffCentralizedEffortsControllerGetCentralizedEffortsRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/bff/centralized-efforts`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => CentralizedEffortsListDtoFromJSON(jsonValue));
        });
    }
    /**
     */
    bffCentralizedEffortsControllerGetCentralizedEfforts(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.bffCentralizedEffortsControllerGetCentralizedEffortsRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    bffCentralizedEffortsControllerGetCentrlizedEffortRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['id'] == null) {
                throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling bffCentralizedEffortsControllerGetCentrlizedEffort().');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/bff/centralized-efforts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => GetCentralizedEffortPageResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     */
    bffCentralizedEffortsControllerGetCentrlizedEffort(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.bffCentralizedEffortsControllerGetCentrlizedEffortRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    bffCompanyControllerGetCompaniesWithDetailsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters['page'] != null) {
                queryParameters['page'] = requestParameters['page'];
            }
            if (requestParameters['limit'] != null) {
                queryParameters['limit'] = requestParameters['limit'];
            }
            if (requestParameters['extraFields'] != null) {
                queryParameters['extraFields'] = requestParameters['extraFields'];
            }
            if (requestParameters['name'] != null) {
                queryParameters['name'] = requestParameters['name'];
            }
            if (requestParameters['priorities'] != null) {
                queryParameters['priorities'] = requestParameters['priorities'];
            }
            if (requestParameters['focusAreas'] != null) {
                queryParameters['focusAreas'] = requestParameters['focusAreas'];
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/bff/company`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => GetExtendedCompanyListResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    bffCompanyControllerGetCompaniesWithDetails(requestParameters = {}, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.bffCompanyControllerGetCompaniesWithDetailsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    bffCompanyControllerGetSingleCompanyRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['id'] == null) {
                throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling bffCompanyControllerGetSingleCompany().');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/bff/company/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => GetCompanyPageResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     */
    bffCompanyControllerGetSingleCompany(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.bffCompanyControllerGetSingleCompanyRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    bffCompanyHealthControllerGetCompaniesHealthRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters['page'] != null) {
                queryParameters['page'] = requestParameters['page'];
            }
            if (requestParameters['limit'] != null) {
                queryParameters['limit'] = requestParameters['limit'];
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/bff/health`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => GetCompaniesHealthPageResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     */
    bffCompanyHealthControllerGetCompaniesHealth(requestParameters = {}, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.bffCompanyHealthControllerGetCompaniesHealthRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    bffKpiControllerFindAllDetailedKpisRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters['page'] != null) {
                queryParameters['page'] = requestParameters['page'];
            }
            if (requestParameters['limit'] != null) {
                queryParameters['limit'] = requestParameters['limit'];
            }
            if (requestParameters['name'] != null) {
                queryParameters['name'] = requestParameters['name'];
            }
            if (requestParameters['matchType'] != null) {
                queryParameters['matchType'] = requestParameters['matchType'];
            }
            if (requestParameters['owningCompanyId'] != null) {
                queryParameters['owningCompanyId'] = requestParameters['owningCompanyId'];
            }
            if (requestParameters['kpiGroupId'] != null) {
                queryParameters['kpiGroupId'] = requestParameters['kpiGroupId'];
            }
            if (requestParameters['groupType'] != null) {
                queryParameters['groupType'] = requestParameters['groupType'];
            }
            if (requestParameters['category'] != null) {
                queryParameters['category'] = requestParameters['category'];
            }
            if (requestParameters['year'] != null) {
                queryParameters['year'] = requestParameters['year'];
            }
            if (requestParameters['quarter'] != null) {
                queryParameters['quarter'] = requestParameters['quarter'];
            }
            if (requestParameters['statusId'] != null) {
                queryParameters['statusId'] = requestParameters['statusId'];
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/bff/kpi/detailed`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     */
    bffKpiControllerFindAllDetailedKpis(requestParameters = {}, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.bffKpiControllerFindAllDetailedKpisRaw(requestParameters, initOverrides);
        });
    }
    /**
     */
    bffKpiControllerFindAllSlimKpisRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters['page'] != null) {
                queryParameters['page'] = requestParameters['page'];
            }
            if (requestParameters['limit'] != null) {
                queryParameters['limit'] = requestParameters['limit'];
            }
            if (requestParameters['name'] != null) {
                queryParameters['name'] = requestParameters['name'];
            }
            if (requestParameters['matchType'] != null) {
                queryParameters['matchType'] = requestParameters['matchType'];
            }
            if (requestParameters['owningCompanyId'] != null) {
                queryParameters['owningCompanyId'] = requestParameters['owningCompanyId'];
            }
            if (requestParameters['kpiGroupId'] != null) {
                queryParameters['kpiGroupId'] = requestParameters['kpiGroupId'];
            }
            if (requestParameters['groupType'] != null) {
                queryParameters['groupType'] = requestParameters['groupType'];
            }
            if (requestParameters['category'] != null) {
                queryParameters['category'] = requestParameters['category'];
            }
            if (requestParameters['year'] != null) {
                queryParameters['year'] = requestParameters['year'];
            }
            if (requestParameters['quarter'] != null) {
                queryParameters['quarter'] = requestParameters['quarter'];
            }
            if (requestParameters['statusId'] != null) {
                queryParameters['statusId'] = requestParameters['statusId'];
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/bff/kpi/slim`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => GetKpiSlimResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     */
    bffKpiControllerFindAllSlimKpis(requestParameters = {}, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.bffKpiControllerFindAllSlimKpisRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    bffKpiControllerGetSingleKpiRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['id'] == null) {
                throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling bffKpiControllerGetSingleKpi().');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/bff/kpi/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => GetKpiPageResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     */
    bffKpiControllerGetSingleKpi(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.bffKpiControllerGetSingleKpiRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    bffKpiGroupControllerGetKpiGroupListRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters['page'] != null) {
                queryParameters['page'] = requestParameters['page'];
            }
            if (requestParameters['limit'] != null) {
                queryParameters['limit'] = requestParameters['limit'];
            }
            if (requestParameters['names'] != null) {
                queryParameters['names'] = requestParameters['names'];
            }
            if (requestParameters['matchType'] != null) {
                queryParameters['matchType'] = requestParameters['matchType'];
            }
            if (requestParameters['owningCompanyIds'] != null) {
                queryParameters['owningCompanyIds'] = requestParameters['owningCompanyIds'];
            }
            if (requestParameters['groupTypes'] != null) {
                queryParameters['groupTypes'] = requestParameters['groupTypes'];
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/bff/kpi-groups`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => GetAllKpiGroupsResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     */
    bffKpiGroupControllerGetKpiGroupList(requestParameters = {}, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.bffKpiGroupControllerGetKpiGroupListRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    bffKpiGroupControllerGetKpiGroupPageRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['id'] == null) {
                throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling bffKpiGroupControllerGetKpiGroupPage().');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/bff/kpi-groups/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => GetKpiGroupPageResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     */
    bffKpiGroupControllerGetKpiGroupPage(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.bffKpiGroupControllerGetKpiGroupPageRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    bffSearchBarControllerGetSearchBarDataRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/bff/search-bar`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => GetSearchBarResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     */
    bffSearchBarControllerGetSearchBarData(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.bffSearchBarControllerGetSearchBarDataRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    companiesExtractorControllerExtractCompanyGoalsRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/companies-extractor/company-goals`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     */
    companiesExtractorControllerExtractCompanyGoals(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.companiesExtractorControllerExtractCompanyGoalsRaw(initOverrides);
        });
    }
    /**
     */
    companiesExtractorControllerExtractHeadOfCompaniesRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/companies-extractor/head-of-companies`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     */
    companiesExtractorControllerExtractHeadOfCompanies(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.companiesExtractorControllerExtractHeadOfCompaniesRaw(initOverrides);
        });
    }
    /**
     */
    companiesExtractorControllerExtractSingleFileRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/companies-extractor`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     */
    companiesExtractorControllerExtractSingleFile(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.companiesExtractorControllerExtractSingleFileRaw(initOverrides);
        });
    }
    /**
     */
    companiesExtractorControllerExtractTeamStructureRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/companies-extractor/team-structure`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     */
    companiesExtractorControllerExtractTeamStructure(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.companiesExtractorControllerExtractTeamStructureRaw(initOverrides);
        });
    }
    /**
     */
    companyControllerGetCompaniesRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters['page'] != null) {
                queryParameters['page'] = requestParameters['page'];
            }
            if (requestParameters['limit'] != null) {
                queryParameters['limit'] = requestParameters['limit'];
            }
            if (requestParameters['extraFields'] != null) {
                queryParameters['extraFields'] = requestParameters['extraFields'];
            }
            if (requestParameters['name'] != null) {
                queryParameters['name'] = requestParameters['name'];
            }
            if (requestParameters['priorities'] != null) {
                queryParameters['priorities'] = requestParameters['priorities'];
            }
            if (requestParameters['focusAreas'] != null) {
                queryParameters['focusAreas'] = requestParameters['focusAreas'];
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/company`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => CompaniesDtoFromJSON(jsonValue));
        });
    }
    /**
     */
    companyControllerGetCompanies(requestParameters = {}, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.companyControllerGetCompaniesRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    focusAreaControllerGetFocusAreasRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters['fields'] != null) {
                queryParameters['fields'] = requestParameters['fields'];
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/focus-areas`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FocusAreaFromJSON));
        });
    }
    /**
     */
    focusAreaControllerGetFocusAreas(requestParameters = {}, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.focusAreaControllerGetFocusAreasRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    healthControllerCheckRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/health`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => HealthControllerCheck200ResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    healthControllerCheck(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.healthControllerCheckRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    homeControllerGetHomeDataRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/bff/home`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => HomeScreenResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     */
    homeControllerGetHomeData(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.homeControllerGetHomeDataRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    kpiActionMatcherControllerMatchKpisAndActionsRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/kpi-action-matcher`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     */
    kpiActionMatcherControllerMatchKpisAndActions(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.kpiActionMatcherControllerMatchKpisAndActionsRaw(initOverrides);
        });
    }
    /**
     */
    kpiControllerCreateRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['kpi'] == null) {
                throw new runtime.RequiredError('kpi', 'Required parameter "kpi" was null or undefined when calling kpiControllerCreate().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/kpi`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: KpiToJSON(requestParameters['kpi']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => KpiFromJSON(jsonValue));
        });
    }
    /**
     */
    kpiControllerCreate(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.kpiControllerCreateRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    kpiControllerFindAllRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters['page'] != null) {
                queryParameters['page'] = requestParameters['page'];
            }
            if (requestParameters['limit'] != null) {
                queryParameters['limit'] = requestParameters['limit'];
            }
            if (requestParameters['name'] != null) {
                queryParameters['name'] = requestParameters['name'];
            }
            if (requestParameters['matchType'] != null) {
                queryParameters['matchType'] = requestParameters['matchType'];
            }
            if (requestParameters['owningCompanyId'] != null) {
                queryParameters['owningCompanyId'] = requestParameters['owningCompanyId'];
            }
            if (requestParameters['kpiGroupId'] != null) {
                queryParameters['kpiGroupId'] = requestParameters['kpiGroupId'];
            }
            if (requestParameters['groupType'] != null) {
                queryParameters['groupType'] = requestParameters['groupType'];
            }
            if (requestParameters['category'] != null) {
                queryParameters['category'] = requestParameters['category'];
            }
            if (requestParameters['year'] != null) {
                queryParameters['year'] = requestParameters['year'];
            }
            if (requestParameters['quarter'] != null) {
                queryParameters['quarter'] = requestParameters['quarter'];
            }
            if (requestParameters['statusId'] != null) {
                queryParameters['statusId'] = requestParameters['statusId'];
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/kpi`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => GetAllKpisResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     */
    kpiControllerFindAll(requestParameters = {}, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.kpiControllerFindAllRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    kpiControllerFindOneRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['id'] == null) {
                throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling kpiControllerFindOne().');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/kpi/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => KpiFromJSON(jsonValue));
        });
    }
    /**
     */
    kpiControllerFindOne(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.kpiControllerFindOneRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    kpiControllerRemoveRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['id'] == null) {
                throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling kpiControllerRemove().');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/kpi/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     */
    kpiControllerRemove(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.kpiControllerRemoveRaw(requestParameters, initOverrides);
        });
    }
    /**
     */
    kpiControllerUpdateRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['id'] == null) {
                throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling kpiControllerUpdate().');
            }
            if (requestParameters['kpi'] == null) {
                throw new runtime.RequiredError('kpi', 'Required parameter "kpi" was null or undefined when calling kpiControllerUpdate().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/kpi/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: KpiToJSON(requestParameters['kpi']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     */
    kpiControllerUpdate(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.kpiControllerUpdateRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    kpiExtractorControllerGetKpiFromDocsRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/kpi-extractor`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     */
    kpiExtractorControllerGetKpiFromDocs(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.kpiExtractorControllerGetKpiFromDocsRaw(initOverrides);
        });
    }
    /**
     */
    kpiKpiGroupMatcherControllerAssociateKpiAndKpiGroupsRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/kpi-kpi-group-matcher`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     */
    kpiKpiGroupMatcherControllerAssociateKpiAndKpiGroups(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.kpiKpiGroupMatcherControllerAssociateKpiAndKpiGroupsRaw(initOverrides);
        });
    }
    /**
     */
    mondayEmployeeImporterControllerProcessMondayRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/monday-employee-importer/process-monday`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     */
    mondayEmployeeImporterControllerProcessMonday(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.mondayEmployeeImporterControllerProcessMondayRaw(initOverrides);
        });
    }
    /**
     */
    mondayEmployeeImporterControllerProcessOriginalDocsRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/monday-employee-importer/process-original-docs`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     */
    mondayEmployeeImporterControllerProcessOriginalDocs(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.mondayEmployeeImporterControllerProcessOriginalDocsRaw(initOverrides);
        });
    }
    /**
     */
    originalDocsEmbedderControllerEmbedAllDocsRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/original-docs-embedder/embed-all`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     */
    originalDocsEmbedderControllerEmbedAllDocs(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.originalDocsEmbedderControllerEmbedAllDocsRaw(initOverrides);
        });
    }
    /**
     */
    originalDocsEmbedderControllerEmbedDocRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['docId'] == null) {
                throw new runtime.RequiredError('docId', 'Required parameter "docId" was null or undefined when calling originalDocsEmbedderControllerEmbedDoc().');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/original-docs-embedder/embed/{docId}`.replace(`{${"docId"}}`, encodeURIComponent(String(requestParameters['docId']))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     */
    originalDocsEmbedderControllerEmbedDoc(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.originalDocsEmbedderControllerEmbedDocRaw(requestParameters, initOverrides);
        });
    }
    /**
     */
    pdfToMarkdownControllerCompanyToMarkdownRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['companyId'] == null) {
                throw new runtime.RequiredError('companyId', 'Required parameter "companyId" was null or undefined when calling pdfToMarkdownControllerCompanyToMarkdown().');
            }
            const queryParameters = {};
            if (requestParameters['companyId'] != null) {
                queryParameters['companyId'] = requestParameters['companyId'];
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/pdf-to-markdown`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            if (this.isJsonMime(response.headers.get('content-type'))) {
                return new runtime.JSONApiResponse(response);
            }
            else {
                return new runtime.TextApiResponse(response);
            }
        });
    }
    /**
     */
    pdfToMarkdownControllerCompanyToMarkdown(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.pdfToMarkdownControllerCompanyToMarkdownRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    tableauClientControllerGetAllDataSourceDataRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['tableauAuthToken'] == null) {
                throw new runtime.RequiredError('tableauAuthToken', 'Required parameter "tableauAuthToken" was null or undefined when calling tableauClientControllerGetAllDataSourceData().');
            }
            if (requestParameters['siteId'] == null) {
                throw new runtime.RequiredError('siteId', 'Required parameter "siteId" was null or undefined when calling tableauClientControllerGetAllDataSourceData().');
            }
            const queryParameters = {};
            if (requestParameters['tableauAuthToken'] != null) {
                queryParameters['tableauAuthToken'] = requestParameters['tableauAuthToken'];
            }
            if (requestParameters['siteId'] != null) {
                queryParameters['siteId'] = requestParameters['siteId'];
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/tableau-client/datasources`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     */
    tableauClientControllerGetAllDataSourceData(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.tableauClientControllerGetAllDataSourceDataRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    tableauClientControllerGetAllWorkbookConnectionsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['tableauAuthToken'] == null) {
                throw new runtime.RequiredError('tableauAuthToken', 'Required parameter "tableauAuthToken" was null or undefined when calling tableauClientControllerGetAllWorkbookConnections().');
            }
            if (requestParameters['siteId'] == null) {
                throw new runtime.RequiredError('siteId', 'Required parameter "siteId" was null or undefined when calling tableauClientControllerGetAllWorkbookConnections().');
            }
            const queryParameters = {};
            if (requestParameters['tableauAuthToken'] != null) {
                queryParameters['tableauAuthToken'] = requestParameters['tableauAuthToken'];
            }
            if (requestParameters['siteId'] != null) {
                queryParameters['siteId'] = requestParameters['siteId'];
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/tableau-client/workbook/connections`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     */
    tableauClientControllerGetAllWorkbookConnections(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.tableauClientControllerGetAllWorkbookConnectionsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    tableauClientControllerGetAllWorkbooksRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['tableauAuthToken'] == null) {
                throw new runtime.RequiredError('tableauAuthToken', 'Required parameter "tableauAuthToken" was null or undefined when calling tableauClientControllerGetAllWorkbooks().');
            }
            if (requestParameters['siteId'] == null) {
                throw new runtime.RequiredError('siteId', 'Required parameter "siteId" was null or undefined when calling tableauClientControllerGetAllWorkbooks().');
            }
            const queryParameters = {};
            if (requestParameters['tableauAuthToken'] != null) {
                queryParameters['tableauAuthToken'] = requestParameters['tableauAuthToken'];
            }
            if (requestParameters['siteId'] != null) {
                queryParameters['siteId'] = requestParameters['siteId'];
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/tableau-client/workbook`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     */
    tableauClientControllerGetAllWorkbooks(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.tableauClientControllerGetAllWorkbooksRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    tableauClientControllerGetWorkbooksViewsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['tableauAuthToken'] == null) {
                throw new runtime.RequiredError('tableauAuthToken', 'Required parameter "tableauAuthToken" was null or undefined when calling tableauClientControllerGetWorkbooksViews().');
            }
            if (requestParameters['siteId'] == null) {
                throw new runtime.RequiredError('siteId', 'Required parameter "siteId" was null or undefined when calling tableauClientControllerGetWorkbooksViews().');
            }
            if (requestParameters['workbookId'] == null) {
                throw new runtime.RequiredError('workbookId', 'Required parameter "workbookId" was null or undefined when calling tableauClientControllerGetWorkbooksViews().');
            }
            const queryParameters = {};
            if (requestParameters['tableauAuthToken'] != null) {
                queryParameters['tableauAuthToken'] = requestParameters['tableauAuthToken'];
            }
            if (requestParameters['siteId'] != null) {
                queryParameters['siteId'] = requestParameters['siteId'];
            }
            if (requestParameters['workbookId'] != null) {
                queryParameters['workbookId'] = requestParameters['workbookId'];
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/tableau-client/workbook/views`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     */
    tableauClientControllerGetWorkbooksViews(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.tableauClientControllerGetWorkbooksViewsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    tableauClientControllerSignInRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['body'] == null) {
                throw new runtime.RequiredError('body', 'Required parameter "body" was null or undefined when calling tableauClientControllerSignIn().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/tableau-client/sign-in`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: requestParameters['body'],
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     */
    tableauClientControllerSignIn(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.tableauClientControllerSignInRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
/**
 * @export
 */
export const ActionsControllerFindAllPrioritiesEnum = {
    Category1: 'category-1',
    Category2: 'category-2',
    Category3: 'category-3',
    None: 'none'
};
/**
 * @export
 */
export const ActionsControllerGetAnalyticsPrioritiesEnum = {
    Category1: 'category-1',
    Category2: 'category-2',
    Category3: 'category-3',
    None: 'none'
};
/**
 * @export
 */
export const AnalyticsControllerGetAnalyticsPrioritiesEnum = {
    Category1: 'category-1',
    Category2: 'category-2',
    Category3: 'category-3',
    None: 'none'
};
/**
 * @export
 */
export const BffCompanyControllerGetCompaniesWithDetailsExtraFieldsEnum = {
    Owner: 'owner',
    EmployeesCount: 'employeesCount'
};
/**
 * @export
 */
export const BffCompanyControllerGetCompaniesWithDetailsPrioritiesEnum = {
    Category1: 'category-1',
    Category2: 'category-2',
    Category3: 'category-3',
    None: 'none'
};
/**
 * @export
 */
export const BffKpiControllerFindAllDetailedKpisGroupTypeEnum = {
    Wix: 'wix',
    PrimeDirective: 'prime-directive'
};
/**
 * @export
 */
export const BffKpiControllerFindAllDetailedKpisCategoryEnum = {
    Quality: 'quality',
    PartnerAgencies: 'partner-agencies',
    Ai: 'ai',
    OpenPlatform: 'open-platform'
};
/**
 * @export
 */
export const BffKpiControllerFindAllSlimKpisGroupTypeEnum = {
    Wix: 'wix',
    PrimeDirective: 'prime-directive'
};
/**
 * @export
 */
export const BffKpiControllerFindAllSlimKpisCategoryEnum = {
    Quality: 'quality',
    PartnerAgencies: 'partner-agencies',
    Ai: 'ai',
    OpenPlatform: 'open-platform'
};
/**
 * @export
 */
export const BffKpiGroupControllerGetKpiGroupListGroupTypesEnum = {
    Wix: 'wix',
    PrimeDirective: 'prime-directive'
};
/**
 * @export
 */
export const CompanyControllerGetCompaniesExtraFieldsEnum = {
    Owner: 'owner',
    EmployeesCount: 'employeesCount'
};
/**
 * @export
 */
export const CompanyControllerGetCompaniesPrioritiesEnum = {
    Category1: 'category-1',
    Category2: 'category-2',
    Category3: 'category-3',
    None: 'none'
};
/**
 * @export
 */
export const FocusAreaControllerGetFocusAreasFieldsEnum = {
    Id: 'id',
    Name: 'name',
    Types: 'types',
    Description: 'description'
};
/**
 * @export
 */
export const KpiControllerFindAllGroupTypeEnum = {
    Wix: 'wix',
    PrimeDirective: 'prime-directive'
};
/**
 * @export
 */
export const KpiControllerFindAllCategoryEnum = {
    Quality: 'quality',
    PartnerAgencies: 'partner-agencies',
    Ai: 'ai',
    OpenPlatform: 'open-platform'
};
