/* tslint:disable */
/* eslint-disable */
/**
 * Game Plan
 * The API description of the almighty Game Plan.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the CompanyStatement interface.
 */
export function instanceOfCompanyStatement(value) {
    if (!('vision' in value))
        return false;
    if (!('mission' in value))
        return false;
    if (!('team' in value))
        return false;
    if (!('opportunity' in value))
        return false;
    if (!('productEcosystem' in value))
        return false;
    if (!('competitionAndStateOfTheArt' in value))
        return false;
    if (!('challenge' in value))
        return false;
    if (!('risks' in value))
        return false;
    if (!('users' in value))
        return false;
    if (!('otherStatements' in value))
        return false;
    return true;
}
export function CompanyStatementFromJSON(json) {
    return CompanyStatementFromJSONTyped(json, false);
}
export function CompanyStatementFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'vision': json['vision'],
        'mission': json['mission'],
        'team': json['team'],
        'opportunity': json['opportunity'],
        'productEcosystem': json['productEcosystem'],
        'competitionAndStateOfTheArt': json['competitionAndStateOfTheArt'],
        'challenge': json['challenge'],
        'risks': json['risks'],
        'users': json['users'],
        'otherStatements': json['otherStatements'],
    };
}
export function CompanyStatementToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'vision': value['vision'],
        'mission': value['mission'],
        'team': value['team'],
        'opportunity': value['opportunity'],
        'productEcosystem': value['productEcosystem'],
        'competitionAndStateOfTheArt': value['competitionAndStateOfTheArt'],
        'challenge': value['challenge'],
        'risks': value['risks'],
        'users': value['users'],
        'otherStatements': value['otherStatements'],
    };
}
