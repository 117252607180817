/* tslint:disable */
/* eslint-disable */
/**
 * Game Plan
 * The API description of the almighty Game Plan.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * @export
 */
export const SlimKpiGroupDtoGroupTypeEnum = {
    Wix: 'wix',
    PrimeDirective: 'prime-directive'
};
/**
 * @export
 */
export const SlimKpiGroupDtoCategoryEnum = {
    Quality: 'quality',
    PartnerAgencies: 'partner-agencies',
    Ai: 'ai',
    OpenPlatform: 'open-platform'
};
/**
 * Check if a given object implements the SlimKpiGroupDto interface.
 */
export function instanceOfSlimKpiGroupDto(value) {
    if (!('id' in value))
        return false;
    if (!('name' in value))
        return false;
    if (!('groupType' in value))
        return false;
    return true;
}
export function SlimKpiGroupDtoFromJSON(json) {
    return SlimKpiGroupDtoFromJSONTyped(json, false);
}
export function SlimKpiGroupDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'name': json['name'],
        'groupType': json['groupType'],
        'category': json['category'] == null ? undefined : json['category'],
    };
}
export function SlimKpiGroupDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'name': value['name'],
        'groupType': value['groupType'],
        'category': value['category'],
    };
}
