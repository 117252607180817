/* tslint:disable */
/* eslint-disable */
/**
 * Game Plan
 * The API description of the almighty Game Plan.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AskMeAnythingStreamMessageDtoFromJSON, AskMeAnythingStreamMessageDtoToJSON, } from './AskMeAnythingStreamMessageDto';
/**
 * Check if a given object implements the AmaStreamMessage interface.
 */
export function instanceOfAmaStreamMessage(value) {
    if (!('data' in value))
        return false;
    return true;
}
export function AmaStreamMessageFromJSON(json) {
    return AmaStreamMessageFromJSONTyped(json, false);
}
export function AmaStreamMessageFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'data': AskMeAnythingStreamMessageDtoFromJSON(json['data']),
    };
}
export function AmaStreamMessageToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'data': AskMeAnythingStreamMessageDtoToJSON(value['data']),
    };
}
