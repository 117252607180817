/* tslint:disable */
/* eslint-disable */
/**
 * Game Plan
 * The API description of the almighty Game Plan.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CompanyFromJSON, CompanyToJSON, } from './Company';
import { EmployeeFromJSON, EmployeeToJSON, } from './Employee';
/**
 * Check if a given object implements the CompanyEmployee interface.
 */
export function instanceOfCompanyEmployee(value) {
    if (!('id' in value))
        return false;
    if (!('role' in value))
        return false;
    if (!('isLeadership' in value))
        return false;
    if (!('companyId' in value))
        return false;
    if (!('company' in value))
        return false;
    if (!('employeeEmail' in value))
        return false;
    if (!('employee' in value))
        return false;
    if (!('createdAt' in value))
        return false;
    if (!('updatedAt' in value))
        return false;
    return true;
}
export function CompanyEmployeeFromJSON(json) {
    return CompanyEmployeeFromJSONTyped(json, false);
}
export function CompanyEmployeeFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'role': json['role'],
        'isLeadership': json['isLeadership'],
        'companyId': json['companyId'],
        'company': CompanyFromJSON(json['company']),
        'employeeEmail': json['employeeEmail'],
        'employee': EmployeeFromJSON(json['employee']),
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
    };
}
export function CompanyEmployeeToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'role': value['role'],
        'isLeadership': value['isLeadership'],
        'companyId': value['companyId'],
        'company': CompanyToJSON(value['company']),
        'employeeEmail': value['employeeEmail'],
        'employee': EmployeeToJSON(value['employee']),
        'createdAt': ((value['createdAt']).toISOString()),
        'updatedAt': ((value['updatedAt']).toISOString()),
    };
}
