/* tslint:disable */
/* eslint-disable */
/**
 * Game Plan
 * The API description of the almighty Game Plan.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CompanyEmployeeFromJSON, CompanyEmployeeToJSON, } from './CompanyEmployee';
import { LinkFromJSON, LinkToJSON, } from './Link';
import { SlimActionDtoFromJSON, SlimActionDtoToJSON, } from './SlimActionDto';
import { SlimCompanyDtoFromJSON, SlimCompanyDtoToJSON, } from './SlimCompanyDto';
import { SlimKpiWithYearStatusDtoFromJSON, SlimKpiWithYearStatusDtoToJSON, } from './SlimKpiWithYearStatusDto';
/**
 * Check if a given object implements the GetKpiPageResponseDto interface.
 */
export function instanceOfGetKpiPageResponseDto(value) {
    if (!('kpi' in value))
        return false;
    if (!('relatedKpis' in value))
        return false;
    if (!('actionsImpactingThisKpi' in value))
        return false;
    if (!('companyEmployees' in value))
        return false;
    if (!('owningCompany' in value))
        return false;
    if (!('links' in value))
        return false;
    return true;
}
export function GetKpiPageResponseDtoFromJSON(json) {
    return GetKpiPageResponseDtoFromJSONTyped(json, false);
}
export function GetKpiPageResponseDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'kpi': SlimKpiWithYearStatusDtoFromJSON(json['kpi']),
        'relatedKpis': (json['relatedKpis'].map(SlimKpiWithYearStatusDtoFromJSON)),
        'actionsImpactingThisKpi': (json['actionsImpactingThisKpi'].map(SlimActionDtoFromJSON)),
        'companyEmployees': (json['companyEmployees'].map(CompanyEmployeeFromJSON)),
        'owningCompany': SlimCompanyDtoFromJSON(json['owningCompany']),
        'links': (json['links'].map(LinkFromJSON)),
    };
}
export function GetKpiPageResponseDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'kpi': SlimKpiWithYearStatusDtoToJSON(value['kpi']),
        'relatedKpis': (value['relatedKpis'].map(SlimKpiWithYearStatusDtoToJSON)),
        'actionsImpactingThisKpi': (value['actionsImpactingThisKpi'].map(SlimActionDtoToJSON)),
        'companyEmployees': (value['companyEmployees'].map(CompanyEmployeeToJSON)),
        'owningCompany': SlimCompanyDtoToJSON(value['owningCompany']),
        'links': (value['links'].map(LinkToJSON)),
    };
}
