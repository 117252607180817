/* tslint:disable */
/* eslint-disable */
/**
 * Game Plan
 * The API description of the almighty Game Plan.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the PieChartValueDto interface.
 */
export function instanceOfPieChartValueDto(value) {
    if (!('value' in value))
        return false;
    if (!('name' in value))
        return false;
    if (!('count' in value))
        return false;
    return true;
}
export function PieChartValueDtoFromJSON(json) {
    return PieChartValueDtoFromJSONTyped(json, false);
}
export function PieChartValueDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'value': json['value'],
        'name': json['name'],
        'count': json['count'],
        'percentage': json['percentage'] == null ? undefined : json['percentage'],
    };
}
export function PieChartValueDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'value': value['value'],
        'name': value['name'],
        'count': value['count'],
        'percentage': value['percentage'],
    };
}
