/* tslint:disable */
/* eslint-disable */
/**
 * Game Plan
 * The API description of the almighty Game Plan.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SlimCompanyDtoFromJSON, SlimCompanyDtoToJSON, } from './SlimCompanyDto';
/**
 * Check if a given object implements the HomeScreenAnnualPlanFocusedCompanyDto interface.
 */
export function instanceOfHomeScreenAnnualPlanFocusedCompanyDto(value) {
    if (!('focusPercentage' in value))
        return false;
    if (!('company' in value))
        return false;
    return true;
}
export function HomeScreenAnnualPlanFocusedCompanyDtoFromJSON(json) {
    return HomeScreenAnnualPlanFocusedCompanyDtoFromJSONTyped(json, false);
}
export function HomeScreenAnnualPlanFocusedCompanyDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'focusPercentage': json['focusPercentage'],
        'company': SlimCompanyDtoFromJSON(json['company']),
    };
}
export function HomeScreenAnnualPlanFocusedCompanyDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'focusPercentage': value['focusPercentage'],
        'company': SlimCompanyDtoToJSON(value['company']),
    };
}
