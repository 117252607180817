/* tslint:disable */
/* eslint-disable */
/**
 * Game Plan
 * The API description of the almighty Game Plan.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the AmaMemorySession interface.
 */
export function instanceOfAmaMemorySession(value) {
    if (!('id' in value))
        return false;
    if (!('userId' in value))
        return false;
    if (!('createdAt' in value))
        return false;
    if (!('deletedAt' in value))
        return false;
    if (!('extraDocumentIds' in value))
        return false;
    return true;
}
export function AmaMemorySessionFromJSON(json) {
    return AmaMemorySessionFromJSONTyped(json, false);
}
export function AmaMemorySessionFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'userId': json['userId'],
        'name': json['name'] == null ? undefined : json['name'],
        'createdAt': (new Date(json['createdAt'])),
        'deletedAt': (new Date(json['deletedAt'])),
        'extraDocumentIds': json['extraDocumentIds'],
    };
}
export function AmaMemorySessionToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'userId': value['userId'],
        'name': value['name'],
        'createdAt': ((value['createdAt']).toISOString()),
        'deletedAt': ((value['deletedAt']).toISOString()),
        'extraDocumentIds': value['extraDocumentIds'],
    };
}
