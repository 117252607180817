/* tslint:disable */
/* eslint-disable */
/**
 * Game Plan
 * The API description of the almighty Game Plan.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SlimCompanyDtoFromJSON, SlimCompanyDtoToJSON, } from './SlimCompanyDto';
/**
 * Check if a given object implements the SlimCompanyAndRelatedCompaniesDto interface.
 */
export function instanceOfSlimCompanyAndRelatedCompaniesDto(value) {
    if (!('company' in value))
        return false;
    if (!('relatedCompanies' in value))
        return false;
    return true;
}
export function SlimCompanyAndRelatedCompaniesDtoFromJSON(json) {
    return SlimCompanyAndRelatedCompaniesDtoFromJSONTyped(json, false);
}
export function SlimCompanyAndRelatedCompaniesDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'company': SlimCompanyDtoFromJSON(json['company']),
        'relatedCompanies': (json['relatedCompanies'].map(SlimCompanyDtoFromJSON)),
    };
}
export function SlimCompanyAndRelatedCompaniesDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'company': SlimCompanyDtoToJSON(value['company']),
        'relatedCompanies': (value['relatedCompanies'].map(SlimCompanyDtoToJSON)),
    };
}
