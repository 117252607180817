/* tslint:disable */
/* eslint-disable */
/**
 * Game Plan
 * The API description of the almighty Game Plan.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PriorityFromJSON, PriorityToJSON, } from './Priority';
import { RelevantPopulationsFromJSON, RelevantPopulationsToJSON, } from './RelevantPopulations';
import { SlimCompanyDtoFromJSON, SlimCompanyDtoToJSON, } from './SlimCompanyDto';
import { SlimKpiWithYearStatusDtoFromJSON, SlimKpiWithYearStatusDtoToJSON, } from './SlimKpiWithYearStatusDto';
import { TimeFramesFromJSON, TimeFramesToJSON, } from './TimeFrames';
/**
 * Check if a given object implements the ActionFsDto interface.
 */
export function instanceOfActionFsDto(value) {
    if (!('id' in value))
        return false;
    if (!('title' in value))
        return false;
    if (!('description' in value))
        return false;
    if (!('dependentTeams' in value))
        return false;
    if (!('focusAreas' in value))
        return false;
    if (!('timeFrames' in value))
        return false;
    if (!('relevantPopulations' in value))
        return false;
    if (!('ownerCompany' in value))
        return false;
    if (!('kpis' in value))
        return false;
    if (!('affectedKpis' in value))
        return false;
    return true;
}
export function ActionFsDtoFromJSON(json) {
    return ActionFsDtoFromJSONTyped(json, false);
}
export function ActionFsDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'title': json['title'],
        'description': json['description'],
        'dependentTeams': (json['dependentTeams'].map(SlimCompanyDtoFromJSON)),
        'focusAreas': json['focusAreas'],
        'timeFrames': (json['timeFrames'].map(TimeFramesFromJSON)),
        'relevantPopulations': (json['relevantPopulations'].map(RelevantPopulationsFromJSON)),
        'ownerCompany': SlimCompanyDtoFromJSON(json['ownerCompany']),
        'priorities': json['priorities'] == null ? undefined : (json['priorities'].map(PriorityFromJSON)),
        'kpis': (json['kpis'].map(SlimKpiWithYearStatusDtoFromJSON)),
        'affectedKpis': json['affectedKpis'],
    };
}
export function ActionFsDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'title': value['title'],
        'description': value['description'],
        'dependentTeams': (value['dependentTeams'].map(SlimCompanyDtoToJSON)),
        'focusAreas': value['focusAreas'],
        'timeFrames': (value['timeFrames'].map(TimeFramesToJSON)),
        'relevantPopulations': (value['relevantPopulations'].map(RelevantPopulationsToJSON)),
        'ownerCompany': SlimCompanyDtoToJSON(value['ownerCompany']),
        'priorities': value['priorities'] == null ? undefined : (value['priorities'].map(PriorityToJSON)),
        'kpis': (value['kpis'].map(SlimKpiWithYearStatusDtoToJSON)),
        'affectedKpis': value['affectedKpis'],
    };
}
