/* tslint:disable */
/* eslint-disable */
/**
 * Game Plan
 * The API description of the almighty Game Plan.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the AmaMentionedDocumentDto interface.
 */
export function instanceOfAmaMentionedDocumentDto(value) {
    if (!('title' in value))
        return false;
    if (!('link' in value))
        return false;
    if (!('username' in value))
        return false;
    if (!('id' in value))
        return false;
    if (!('updatedAt' in value))
        return false;
    return true;
}
export function AmaMentionedDocumentDtoFromJSON(json) {
    return AmaMentionedDocumentDtoFromJSONTyped(json, false);
}
export function AmaMentionedDocumentDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'title': json['title'],
        'link': json['link'],
        'username': json['username'],
        'id': json['id'],
        'updatedAt': (new Date(json['updatedAt'])),
    };
}
export function AmaMentionedDocumentDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'title': value['title'],
        'link': value['link'],
        'username': value['username'],
        'id': value['id'],
        'updatedAt': ((value['updatedAt']).toISOString()),
    };
}
