/* tslint:disable */
/* eslint-disable */
/**
 * Game Plan
 * The API description of the almighty Game Plan.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SlimEmployeeDtoFromJSON, SlimEmployeeDtoToJSON, } from './SlimEmployeeDto';
/**
 * Check if a given object implements the SlimCompanyDto interface.
 */
export function instanceOfSlimCompanyDto(value) {
    if (!('id' in value))
        return false;
    if (!('name' in value))
        return false;
    return true;
}
export function SlimCompanyDtoFromJSON(json) {
    return SlimCompanyDtoFromJSONTyped(json, false);
}
export function SlimCompanyDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'name': json['name'],
        'owner': json['owner'] == null ? undefined : SlimEmployeeDtoFromJSON(json['owner']),
    };
}
export function SlimCompanyDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'name': value['name'],
        'owner': SlimEmployeeDtoToJSON(value['owner']),
    };
}
