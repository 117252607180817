/* tslint:disable */
/* eslint-disable */
/**
 * Game Plan
 * The API description of the almighty Game Plan.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const App = {
    Gmail: 'gmail',
    Gdrive: 'gdrive',
    Gdocs: 'gdocs',
    Gsheets: 'gsheets',
    Gslides: 'gslides',
    Gcalendar: 'gcalendar',
    Slack: 'slack',
    Jira: 'jira',
    Salesforce: 'salesforce',
    Github: 'github',
    Notion: 'notion',
    Monday: 'monday',
    Thegist: 'thegist'
};
export function AppFromJSON(json) {
    return AppFromJSONTyped(json, false);
}
export function AppFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function AppToJSON(value) {
    return value;
}
