/* tslint:disable */
/* eslint-disable */
/**
 * Game Plan
 * The API description of the almighty Game Plan.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';
import { HealthControllerCheck200ResponseInfoValueFromJSON, HealthControllerCheck200ResponseInfoValueToJSON, } from './HealthControllerCheck200ResponseInfoValue';
/**
 * Check if a given object implements the HealthControllerCheck200Response interface.
 */
export function instanceOfHealthControllerCheck200Response(value) {
    return true;
}
export function HealthControllerCheck200ResponseFromJSON(json) {
    return HealthControllerCheck200ResponseFromJSONTyped(json, false);
}
export function HealthControllerCheck200ResponseFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'status': json['status'] == null ? undefined : json['status'],
        'info': json['info'] == null ? undefined : (mapValues(json['info'], HealthControllerCheck200ResponseInfoValueFromJSON)),
        'error': json['error'] == null ? undefined : (mapValues(json['error'], HealthControllerCheck200ResponseInfoValueFromJSON)),
        'details': json['details'] == null ? undefined : (mapValues(json['details'], HealthControllerCheck200ResponseInfoValueFromJSON)),
    };
}
export function HealthControllerCheck200ResponseToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'status': value['status'],
        'info': value['info'] == null ? undefined : (mapValues(value['info'], HealthControllerCheck200ResponseInfoValueToJSON)),
        'error': value['error'] == null ? undefined : (mapValues(value['error'], HealthControllerCheck200ResponseInfoValueToJSON)),
        'details': value['details'] == null ? undefined : (mapValues(value['details'], HealthControllerCheck200ResponseInfoValueToJSON)),
    };
}
