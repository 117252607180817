/* tslint:disable */
/* eslint-disable */
/**
 * Game Plan
 * The API description of the almighty Game Plan.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { KpiFromJSON, KpiToJSON, } from './Kpi';
/**
 * @export
 */
export const KpiGroupGroupTypeEnum = {
    Wix: 'wix',
    PrimeDirective: 'prime-directive'
};
/**
 * @export
 */
export const KpiGroupCategoryEnum = {
    Quality: 'quality',
    PartnerAgencies: 'partner-agencies',
    Ai: 'ai',
    OpenPlatform: 'open-platform'
};
/**
 * Check if a given object implements the KpiGroup interface.
 */
export function instanceOfKpiGroup(value) {
    if (!('id' in value))
        return false;
    if (!('name' in value))
        return false;
    if (!('groupType' in value))
        return false;
    if (!('createdAt' in value))
        return false;
    if (!('updatedAt' in value))
        return false;
    return true;
}
export function KpiGroupFromJSON(json) {
    return KpiGroupFromJSONTyped(json, false);
}
export function KpiGroupFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'kpis': json['kpis'] == null ? undefined : (json['kpis'].map(KpiFromJSON)),
        'id': json['id'],
        'name': json['name'],
        'groupType': json['groupType'],
        'category': json['category'] == null ? undefined : json['category'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
    };
}
export function KpiGroupToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'kpis': value['kpis'] == null ? undefined : (value['kpis'].map(KpiToJSON)),
        'id': value['id'],
        'name': value['name'],
        'groupType': value['groupType'],
        'category': value['category'],
        'createdAt': ((value['createdAt']).toISOString()),
        'updatedAt': ((value['updatedAt']).toISOString()),
    };
}
