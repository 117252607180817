/* tslint:disable */
/* eslint-disable */
/**
 * Game Plan
 * The API description of the almighty Game Plan.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the AuthPingDto interface.
 */
export function instanceOfAuthPingDto(value) {
    if (!('ok' in value))
        return false;
    return true;
}
export function AuthPingDtoFromJSON(json) {
    return AuthPingDtoFromJSONTyped(json, false);
}
export function AuthPingDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'ok': json['ok'],
        'user': json['user'] == null ? undefined : json['user'],
    };
}
export function AuthPingDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'ok': value['ok'],
        'user': value['user'],
    };
}
