/* tslint:disable */
/* eslint-disable */
/**
 * Game Plan
 * The API description of the almighty Game Plan.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CompanyFromJSON, CompanyToJSON, } from './Company';
import { HealthFromJSON, HealthToJSON, } from './Health';
import { HealthIssuesFromJSON, HealthIssuesToJSON, } from './HealthIssues';
/**
 * Check if a given object implements the CompanyHealthDto interface.
 */
export function instanceOfCompanyHealthDto(value) {
    if (!('company' in value))
        return false;
    if (!('health' in value))
        return false;
    if (!('numOfKpis' in value))
        return false;
    if (!('numOfActions' in value))
        return false;
    if (!('healthIssues' in value))
        return false;
    return true;
}
export function CompanyHealthDtoFromJSON(json) {
    return CompanyHealthDtoFromJSONTyped(json, false);
}
export function CompanyHealthDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'company': CompanyFromJSON(json['company']),
        'health': HealthFromJSON(json['health']),
        'numOfKpis': json['numOfKpis'],
        'numOfActions': json['numOfActions'],
        'healthIssues': (json['healthIssues'].map(HealthIssuesFromJSON)),
    };
}
export function CompanyHealthDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'company': CompanyToJSON(value['company']),
        'health': HealthToJSON(value['health']),
        'numOfKpis': value['numOfKpis'],
        'numOfActions': value['numOfActions'],
        'healthIssues': (value['healthIssues'].map(HealthIssuesToJSON)),
    };
}
