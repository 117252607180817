/* tslint:disable */
/* eslint-disable */
/**
 * Game Plan
 * The API description of the almighty Game Plan.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { KpiFromJSON, KpiToJSON, } from './Kpi';
/**
 * @export
 */
export const KpiStatusUnitEnum = {
    Number: 'number',
    Dollars: 'dollars',
    Percentage: 'percentage',
    Boolean: 'boolean'
};
/**
 * Check if a given object implements the KpiStatus interface.
 */
export function instanceOfKpiStatus(value) {
    if (!('id' in value))
        return false;
    if (!('owningCompanyId' in value))
        return false;
    if (!('kpiId' in value))
        return false;
    if (!('kpi' in value))
        return false;
    if (!('startingPoint' in value))
        return false;
    if (!('year' in value))
        return false;
    if (!('unit' in value))
        return false;
    if (!('isDecreasing' in value))
        return false;
    if (!('createdAt' in value))
        return false;
    if (!('updatedAt' in value))
        return false;
    return true;
}
export function KpiStatusFromJSON(json) {
    return KpiStatusFromJSONTyped(json, false);
}
export function KpiStatusFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'owningCompanyId': json['owningCompanyId'],
        'kpiId': json['kpiId'],
        'kpi': KpiFromJSON(json['kpi']),
        'actual': json['actual'] == null ? undefined : json['actual'],
        'actualText': json['actualText'] == null ? undefined : json['actualText'],
        'target': json['target'] == null ? undefined : json['target'],
        'targetText': json['targetText'] == null ? undefined : json['targetText'],
        'startingPoint': json['startingPoint'],
        'year': json['year'],
        'unit': json['unit'],
        'quarter': json['quarter'] == null ? undefined : json['quarter'],
        'isDecreasing': json['isDecreasing'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
    };
}
export function KpiStatusToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'owningCompanyId': value['owningCompanyId'],
        'kpiId': value['kpiId'],
        'kpi': KpiToJSON(value['kpi']),
        'actual': value['actual'],
        'actualText': value['actualText'],
        'target': value['target'],
        'targetText': value['targetText'],
        'startingPoint': value['startingPoint'],
        'year': value['year'],
        'unit': value['unit'],
        'quarter': value['quarter'],
        'isDecreasing': value['isDecreasing'],
        'createdAt': ((value['createdAt']).toISOString()),
        'updatedAt': ((value['updatedAt']).toISOString()),
    };
}
