/* tslint:disable */
/* eslint-disable */
/**
 * Game Plan
 * The API description of the almighty Game Plan.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * @export
 */
export const GetUserAccessStateDtoStateEnum = {
    InvitedButNotLoggedIn: 'invited-but-not-logged-in',
    LoggedInButNoAccess: 'logged-in-but-no-access',
    LoggedInAndRequestedAccess: 'logged-in-and-requested-access',
    Active: 'active',
    Disabled: 'disabled'
};
/**
 * Check if a given object implements the GetUserAccessStateDto interface.
 */
export function instanceOfGetUserAccessStateDto(value) {
    if (!('state' in value))
        return false;
    return true;
}
export function GetUserAccessStateDtoFromJSON(json) {
    return GetUserAccessStateDtoFromJSONTyped(json, false);
}
export function GetUserAccessStateDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'state': json['state'],
    };
}
export function GetUserAccessStateDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'state': value['state'],
    };
}
