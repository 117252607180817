/* tslint:disable */
/* eslint-disable */
/**
 * Game Plan
 * The API description of the almighty Game Plan.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the DeleteSessionRequestDto interface.
 */
export function instanceOfDeleteSessionRequestDto(value) {
    if (!('sessionId' in value))
        return false;
    return true;
}
export function DeleteSessionRequestDtoFromJSON(json) {
    return DeleteSessionRequestDtoFromJSONTyped(json, false);
}
export function DeleteSessionRequestDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'sessionId': json['sessionId'],
    };
}
export function DeleteSessionRequestDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'sessionId': value['sessionId'],
    };
}
