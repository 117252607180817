/* tslint:disable */
/* eslint-disable */
/**
 * Game Plan
 * The API description of the almighty Game Plan.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the AmaMemoryDto interface.
 */
export function instanceOfAmaMemoryDto(value) {
    if (!('id' in value))
        return false;
    if (!('userId' in value))
        return false;
    if (!('question' in value))
        return false;
    if (!('answer' in value))
        return false;
    if (!('createdAt' in value))
        return false;
    return true;
}
export function AmaMemoryDtoFromJSON(json) {
    return AmaMemoryDtoFromJSONTyped(json, false);
}
export function AmaMemoryDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'userId': json['userId'],
        'question': json['question'],
        'answer': json['answer'],
        'createdAt': (new Date(json['createdAt'])),
    };
}
export function AmaMemoryDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'userId': value['userId'],
        'question': value['question'],
        'answer': value['answer'],
        'createdAt': ((value['createdAt']).toISOString()),
    };
}
