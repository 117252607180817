/* tslint:disable */
/* eslint-disable */
/**
 * Game Plan
 * The API description of the almighty Game Plan.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SlimCompanyAndRelatedCompaniesDtoFromJSON, SlimCompanyAndRelatedCompaniesDtoToJSON, } from './SlimCompanyAndRelatedCompaniesDto';
/**
 * Check if a given object implements the HomeCompaniesDto interface.
 */
export function instanceOfHomeCompaniesDto(value) {
    if (!('totalCompanies' in value))
        return false;
    if (!('missingCompanies' in value))
        return false;
    if (!('newCompanies' in value))
        return false;
    if (!('mostDependent' in value))
        return false;
    if (!('mostReliedUpon' in value))
        return false;
    return true;
}
export function HomeCompaniesDtoFromJSON(json) {
    return HomeCompaniesDtoFromJSONTyped(json, false);
}
export function HomeCompaniesDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'totalCompanies': json['totalCompanies'],
        'missingCompanies': json['missingCompanies'],
        'newCompanies': json['newCompanies'],
        'mostDependent': (json['mostDependent'].map(SlimCompanyAndRelatedCompaniesDtoFromJSON)),
        'mostReliedUpon': (json['mostReliedUpon'].map(SlimCompanyAndRelatedCompaniesDtoFromJSON)),
    };
}
export function HomeCompaniesDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'totalCompanies': value['totalCompanies'],
        'missingCompanies': value['missingCompanies'],
        'newCompanies': value['newCompanies'],
        'mostDependent': (value['mostDependent'].map(SlimCompanyAndRelatedCompaniesDtoToJSON)),
        'mostReliedUpon': (value['mostReliedUpon'].map(SlimCompanyAndRelatedCompaniesDtoToJSON)),
    };
}
