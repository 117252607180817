/* tslint:disable */
/* eslint-disable */
/**
 * Game Plan
 * The API description of the almighty Game Plan.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ActionFromJSON, ActionToJSON, } from './Action';
import { FocusAreaFromJSON, FocusAreaToJSON, } from './FocusArea';
import { SlimKpiWithYearStatusDtoFromJSON, SlimKpiWithYearStatusDtoToJSON, } from './SlimKpiWithYearStatusDto';
/**
 * Check if a given object implements the GetCentralizedEffortPageResponseDto interface.
 */
export function instanceOfGetCentralizedEffortPageResponseDto(value) {
    if (!('focusArea' in value))
        return false;
    if (!('actions' in value))
        return false;
    if (!('totalActions' in value))
        return false;
    if (!('kpis' in value))
        return false;
    if (!('totalKpis' in value))
        return false;
    return true;
}
export function GetCentralizedEffortPageResponseDtoFromJSON(json) {
    return GetCentralizedEffortPageResponseDtoFromJSONTyped(json, false);
}
export function GetCentralizedEffortPageResponseDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'focusArea': FocusAreaFromJSON(json['focusArea']),
        'actions': (json['actions'].map(ActionFromJSON)),
        'totalActions': json['totalActions'],
        'kpis': (json['kpis'].map(SlimKpiWithYearStatusDtoFromJSON)),
        'totalKpis': json['totalKpis'],
    };
}
export function GetCentralizedEffortPageResponseDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'focusArea': FocusAreaToJSON(value['focusArea']),
        'actions': (value['actions'].map(ActionToJSON)),
        'totalActions': value['totalActions'],
        'kpis': (value['kpis'].map(SlimKpiWithYearStatusDtoToJSON)),
        'totalKpis': value['totalKpis'],
    };
}
