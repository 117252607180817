/* tslint:disable */
/* eslint-disable */
/**
 * Game Plan
 * The API description of the almighty Game Plan.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PieChartDtoFromJSON, PieChartDtoToJSON, } from './PieChartDto';
import { SlimActionDtoFromJSON, SlimActionDtoToJSON, } from './SlimActionDto';
/**
 * Check if a given object implements the HomeActionsDto interface.
 */
export function instanceOfHomeActionsDto(value) {
    if (!('totalActions' in value))
        return false;
    if (!('actionsWithoutPriority' in value))
        return false;
    if (!('actionsWithoutAffectedKpis' in value))
        return false;
    if (!('comingTimeFrame' in value))
        return false;
    if (!('byPriority' in value))
        return false;
    if (!('byFocusArea' in value))
        return false;
    if (!('byPopulation' in value))
        return false;
    if (!('actionsInComingTimeFrame' in value))
        return false;
    if (!('totalActionsInComingTimeFrame' in value))
        return false;
    return true;
}
export function HomeActionsDtoFromJSON(json) {
    return HomeActionsDtoFromJSONTyped(json, false);
}
export function HomeActionsDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'totalActions': json['totalActions'],
        'actionsWithoutPriority': json['actionsWithoutPriority'],
        'actionsWithoutAffectedKpis': json['actionsWithoutAffectedKpis'],
        'comingTimeFrame': json['comingTimeFrame'],
        'byPriority': PieChartDtoFromJSON(json['byPriority']),
        'byFocusArea': PieChartDtoFromJSON(json['byFocusArea']),
        'byPopulation': PieChartDtoFromJSON(json['byPopulation']),
        'actionsInComingTimeFrame': (json['actionsInComingTimeFrame'].map(SlimActionDtoFromJSON)),
        'totalActionsInComingTimeFrame': json['totalActionsInComingTimeFrame'],
    };
}
export function HomeActionsDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'totalActions': value['totalActions'],
        'actionsWithoutPriority': value['actionsWithoutPriority'],
        'actionsWithoutAffectedKpis': value['actionsWithoutAffectedKpis'],
        'comingTimeFrame': value['comingTimeFrame'],
        'byPriority': PieChartDtoToJSON(value['byPriority']),
        'byFocusArea': PieChartDtoToJSON(value['byFocusArea']),
        'byPopulation': PieChartDtoToJSON(value['byPopulation']),
        'actionsInComingTimeFrame': (value['actionsInComingTimeFrame'].map(SlimActionDtoToJSON)),
        'totalActionsInComingTimeFrame': value['totalActionsInComingTimeFrame'],
    };
}
