/* tslint:disable */
/* eslint-disable */
/**
 * Game Plan
 * The API description of the almighty Game Plan.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LinkFromJSON, LinkToJSON, } from './Link';
/**
 * Check if a given object implements the LinksDto interface.
 */
export function instanceOfLinksDto(value) {
    if (!('links' in value))
        return false;
    return true;
}
export function LinksDtoFromJSON(json) {
    return LinksDtoFromJSONTyped(json, false);
}
export function LinksDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'links': (json['links'].map(LinkFromJSON)),
    };
}
export function LinksDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'links': (value['links'].map(LinkToJSON)),
    };
}
