/* tslint:disable */
/* eslint-disable */
/**
 * Game Plan
 * The API description of the almighty Game Plan.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SlimCompanyDtoFromJSON, SlimCompanyDtoToJSON, } from './SlimCompanyDto';
/**
 * Check if a given object implements the CompanyAndDescriptionDto interface.
 */
export function instanceOfCompanyAndDescriptionDto(value) {
    if (!('company' in value))
        return false;
    if (!('description' in value))
        return false;
    return true;
}
export function CompanyAndDescriptionDtoFromJSON(json) {
    return CompanyAndDescriptionDtoFromJSONTyped(json, false);
}
export function CompanyAndDescriptionDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'company': SlimCompanyDtoFromJSON(json['company']),
        'description': json['description'],
    };
}
export function CompanyAndDescriptionDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'company': SlimCompanyDtoToJSON(value['company']),
        'description': value['description'],
    };
}
