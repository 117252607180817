/* tslint:disable */
/* eslint-disable */
/**
 * Game Plan
 * The API description of the almighty Game Plan.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ActionFsDtoFromJSON, ActionFsDtoToJSON, } from './ActionFsDto';
import { CompanyEmployeeFromJSON, CompanyEmployeeToJSON, } from './CompanyEmployee';
import { LinkFromJSON, LinkToJSON, } from './Link';
import { SlimActionDtoFromJSON, SlimActionDtoToJSON, } from './SlimActionDto';
/**
 * Check if a given object implements the GetActionPageResponseDto interface.
 */
export function instanceOfGetActionPageResponseDto(value) {
    if (!('action' in value))
        return false;
    if (!('companyLeadership' in value))
        return false;
    if (!('companyEmployees' in value))
        return false;
    if (!('companyEmployeesCount' in value))
        return false;
    if (!('sameGoalActions' in value))
        return false;
    if (!('allCompanyActions' in value))
        return false;
    if (!('links' in value))
        return false;
    return true;
}
export function GetActionPageResponseDtoFromJSON(json) {
    return GetActionPageResponseDtoFromJSONTyped(json, false);
}
export function GetActionPageResponseDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'action': ActionFsDtoFromJSON(json['action']),
        'companyLeadership': (json['companyLeadership'].map(CompanyEmployeeFromJSON)),
        'companyEmployees': (json['companyEmployees'].map(CompanyEmployeeFromJSON)),
        'companyEmployeesCount': json['companyEmployeesCount'],
        'sameGoalActions': (json['sameGoalActions'].map(SlimActionDtoFromJSON)),
        'allCompanyActions': (json['allCompanyActions'].map(SlimActionDtoFromJSON)),
        'links': (json['links'].map(LinkFromJSON)),
    };
}
export function GetActionPageResponseDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'action': ActionFsDtoToJSON(value['action']),
        'companyLeadership': (value['companyLeadership'].map(CompanyEmployeeToJSON)),
        'companyEmployees': (value['companyEmployees'].map(CompanyEmployeeToJSON)),
        'companyEmployeesCount': value['companyEmployeesCount'],
        'sameGoalActions': (value['sameGoalActions'].map(SlimActionDtoToJSON)),
        'allCompanyActions': (value['allCompanyActions'].map(SlimActionDtoToJSON)),
        'links': (value['links'].map(LinkToJSON)),
    };
}
