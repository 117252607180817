/* tslint:disable */
/* eslint-disable */
/**
 * Game Plan
 * The API description of the almighty Game Plan.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CompanyFromJSON, CompanyToJSON, } from './Company';
import { KpiFromJSON, KpiToJSON, } from './Kpi';
import { PopulationFromJSON, PopulationToJSON, } from './Population';
import { PriorityFromJSON, PriorityToJSON, } from './Priority';
import { TimeFrameFromJSON, TimeFrameToJSON, } from './TimeFrame';
/**
 * Check if a given object implements the Action interface.
 */
export function instanceOfAction(value) {
    if (!('id' in value))
        return false;
    if (!('title' in value))
        return false;
    if (!('description' in value))
        return false;
    if (!('ownerCompany' in value))
        return false;
    if (!('originalTimeFrames' in value))
        return false;
    if (!('originalRelevantPopulations' in value))
        return false;
    if (!('affectedKpis' in value))
        return false;
    if (!('originalFocusAreas' in value))
        return false;
    if (!('year' in value))
        return false;
    if (!('createdAt' in value))
        return false;
    if (!('updatedAt' in value))
        return false;
    if (!('ownerCompanyId' in value))
        return false;
    return true;
}
export function ActionFromJSON(json) {
    return ActionFromJSONTyped(json, false);
}
export function ActionFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'title': json['title'],
        'description': json['description'],
        'ownerCompany': CompanyFromJSON(json['ownerCompany']),
        'priorities': json['priorities'] == null ? undefined : (json['priorities'].map(PriorityFromJSON)),
        'originalTimeFrames': json['originalTimeFrames'],
        'timeFrames': json['timeFrames'] == null ? undefined : (json['timeFrames'].map(TimeFrameFromJSON)),
        'originalRelevantPopulations': json['originalRelevantPopulations'],
        'relevantPopulations': json['relevantPopulations'] == null ? undefined : (json['relevantPopulations'].map(PopulationFromJSON)),
        'dependentTeams': json['dependentTeams'] == null ? undefined : (json['dependentTeams'].map(CompanyFromJSON)),
        'originalDependentTeamIds': json['originalDependentTeamIds'] == null ? undefined : json['originalDependentTeamIds'],
        'dependentTeamIds': json['dependentTeamIds'] == null ? undefined : json['dependentTeamIds'],
        'affectedKpis': json['affectedKpis'],
        'kpis': json['kpis'] == null ? undefined : (json['kpis'].map(KpiFromJSON)),
        'originalFocusAreas': json['originalFocusAreas'],
        'focusAreas': json['focusAreas'] == null ? undefined : json['focusAreas'],
        'year': json['year'],
        'quarter': json['quarter'] == null ? undefined : json['quarter'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
        'ownerCompanyId': json['ownerCompanyId'],
    };
}
export function ActionToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'title': value['title'],
        'description': value['description'],
        'ownerCompany': CompanyToJSON(value['ownerCompany']),
        'priorities': value['priorities'] == null ? undefined : (value['priorities'].map(PriorityToJSON)),
        'originalTimeFrames': value['originalTimeFrames'],
        'timeFrames': value['timeFrames'] == null ? undefined : (value['timeFrames'].map(TimeFrameToJSON)),
        'originalRelevantPopulations': value['originalRelevantPopulations'],
        'relevantPopulations': value['relevantPopulations'] == null ? undefined : (value['relevantPopulations'].map(PopulationToJSON)),
        'dependentTeams': value['dependentTeams'] == null ? undefined : (value['dependentTeams'].map(CompanyToJSON)),
        'originalDependentTeamIds': value['originalDependentTeamIds'],
        'dependentTeamIds': value['dependentTeamIds'],
        'affectedKpis': value['affectedKpis'],
        'kpis': value['kpis'] == null ? undefined : (value['kpis'].map(KpiToJSON)),
        'originalFocusAreas': value['originalFocusAreas'],
        'focusAreas': value['focusAreas'],
        'year': value['year'],
        'quarter': value['quarter'],
        'createdAt': ((value['createdAt']).toISOString()),
        'updatedAt': ((value['updatedAt']).toISOString()),
        'ownerCompanyId': value['ownerCompanyId'],
    };
}
