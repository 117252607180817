/* tslint:disable */
/* eslint-disable */
/**
 * Game Plan
 * The API description of the almighty Game Plan.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HomeActionsDtoFromJSON, HomeActionsDtoToJSON, } from './HomeActionsDto';
import { HomeCompaniesDtoFromJSON, HomeCompaniesDtoToJSON, } from './HomeCompaniesDto';
import { HomeKpisDtoFromJSON, HomeKpisDtoToJSON, } from './HomeKpisDto';
import { HomeScreenAnnualPlanDtoFromJSON, HomeScreenAnnualPlanDtoToJSON, } from './HomeScreenAnnualPlanDto';
/**
 * Check if a given object implements the HomeScreenResponseDto interface.
 */
export function instanceOfHomeScreenResponseDto(value) {
    if (!('annualPlan' in value))
        return false;
    if (!('companies' in value))
        return false;
    if (!('actions' in value))
        return false;
    if (!('kpis' in value))
        return false;
    return true;
}
export function HomeScreenResponseDtoFromJSON(json) {
    return HomeScreenResponseDtoFromJSONTyped(json, false);
}
export function HomeScreenResponseDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'annualPlan': HomeScreenAnnualPlanDtoFromJSON(json['annualPlan']),
        'companies': HomeCompaniesDtoFromJSON(json['companies']),
        'actions': HomeActionsDtoFromJSON(json['actions']),
        'kpis': HomeKpisDtoFromJSON(json['kpis']),
    };
}
export function HomeScreenResponseDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'annualPlan': HomeScreenAnnualPlanDtoToJSON(value['annualPlan']),
        'companies': HomeCompaniesDtoToJSON(value['companies']),
        'actions': HomeActionsDtoToJSON(value['actions']),
        'kpis': HomeKpisDtoToJSON(value['kpis']),
    };
}
