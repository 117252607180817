/* tslint:disable */
/* eslint-disable */
/**
 * Game Plan
 * The API description of the almighty Game Plan.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ActionFromJSON, ActionToJSON, } from './Action';
import { CompanyFromJSON, CompanyToJSON, } from './Company';
import { KpiGroupFromJSON, KpiGroupToJSON, } from './KpiGroup';
import { KpiStatusFromJSON, KpiStatusToJSON, } from './KpiStatus';
/**
 * Check if a given object implements the Kpi interface.
 */
export function instanceOfKpi(value) {
    if (!('id' in value))
        return false;
    if (!('name' in value))
        return false;
    if (!('description' in value))
        return false;
    if (!('owningCompany' in value))
        return false;
    if (!('comments' in value))
        return false;
    if (!('createdAt' in value))
        return false;
    if (!('updatedAt' in value))
        return false;
    if (!('llmGroupAssociationReason' in value))
        return false;
    if (!('llmGroupAssociationConfidence' in value))
        return false;
    return true;
}
export function KpiFromJSON(json) {
    return KpiFromJSONTyped(json, false);
}
export function KpiFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'statuses': json['statuses'] == null ? undefined : (json['statuses'].map(KpiStatusFromJSON)),
        'actions': json['actions'] == null ? undefined : (json['actions'].map(ActionFromJSON)),
        'id': json['id'],
        'name': json['name'],
        'description': json['description'],
        'owningCompanyId': json['owningCompanyId'] == null ? undefined : json['owningCompanyId'],
        'owningCompany': CompanyFromJSON(json['owningCompany']),
        'kpiGroupId': json['kpiGroupId'] == null ? undefined : json['kpiGroupId'],
        'kpiGroup': json['kpiGroup'] == null ? undefined : KpiGroupFromJSON(json['kpiGroup']),
        'comments': json['comments'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
        'llmGroupAssociationReason': json['llmGroupAssociationReason'],
        'llmGroupAssociationConfidence': json['llmGroupAssociationConfidence'],
    };
}
export function KpiToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'statuses': value['statuses'] == null ? undefined : (value['statuses'].map(KpiStatusToJSON)),
        'actions': value['actions'] == null ? undefined : (value['actions'].map(ActionToJSON)),
        'id': value['id'],
        'name': value['name'],
        'description': value['description'],
        'owningCompanyId': value['owningCompanyId'],
        'owningCompany': CompanyToJSON(value['owningCompany']),
        'kpiGroupId': value['kpiGroupId'],
        'kpiGroup': KpiGroupToJSON(value['kpiGroup']),
        'comments': value['comments'],
        'createdAt': ((value['createdAt']).toISOString()),
        'updatedAt': ((value['updatedAt']).toISOString()),
        'llmGroupAssociationReason': value['llmGroupAssociationReason'],
        'llmGroupAssociationConfidence': value['llmGroupAssociationConfidence'],
    };
}
