/* tslint:disable */
/* eslint-disable */
/**
 * Game Plan
 * The API description of the almighty Game Plan.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppFromJSON, AppToJSON, } from './App';
/**
 * Check if a given object implements the Link interface.
 */
export function instanceOfLink(value) {
    if (!('app' in value))
        return false;
    if (!('url' in value))
        return false;
    if (!('prettyText' in value))
        return false;
    return true;
}
export function LinkFromJSON(json) {
    return LinkFromJSONTyped(json, false);
}
export function LinkFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'app': AppFromJSON(json['app']),
        'url': json['url'],
        'prettyText': json['prettyText'],
    };
}
export function LinkToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'app': AppToJSON(value['app']),
        'url': value['url'],
        'prettyText': value['prettyText'],
    };
}
