export const getStorageValue = <T>(key: string, defaultValue: T): T => {
  if (typeof localStorage === 'undefined') {
    return defaultValue;
  }

  const saved = localStorage?.getItem(key);
  if (!saved) {
    return defaultValue;
  }

  try {
    return JSON.parse(saved);
  } catch (e) {
    removeStorageValue(key);
    return defaultValue;
  }
};

export const setStorageValue = <T>(key: string, value?: T) => {
  if (typeof localStorage === 'undefined') {
    return;
  }

  localStorage?.setItem(key, JSON.stringify(value));
};

export const removeStorageValue = (key: string) => {
  if (typeof localStorage === 'undefined') {
    return;
  }

  localStorage?.removeItem(key);
};
