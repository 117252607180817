/* tslint:disable */
/* eslint-disable */
/**
 * Game Plan
 * The API description of the almighty Game Plan.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the SlimEmployeeWithRoleDto interface.
 */
export function instanceOfSlimEmployeeWithRoleDto(value) {
    if (!('email' in value))
        return false;
    if (!('fullName' in value))
        return false;
    if (!('role' in value))
        return false;
    return true;
}
export function SlimEmployeeWithRoleDtoFromJSON(json) {
    return SlimEmployeeWithRoleDtoFromJSONTyped(json, false);
}
export function SlimEmployeeWithRoleDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'email': json['email'],
        'fullName': json['fullName'],
        'avatarUrl': json['avatarUrl'] == null ? undefined : json['avatarUrl'],
        'role': json['role'],
        'phone': json['phone'] == null ? undefined : json['phone'],
    };
}
export function SlimEmployeeWithRoleDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'email': value['email'],
        'fullName': value['fullName'],
        'avatarUrl': value['avatarUrl'],
        'role': value['role'],
        'phone': value['phone'],
    };
}
