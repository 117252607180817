/* tslint:disable */
/* eslint-disable */
/**
 * Game Plan
 * The API description of the almighty Game Plan.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const HealthIssues = {
    NoActions: 'no actions',
    NoKpis: 'no kpis',
    KpisWithNoActions: 'kpis with no actions',
    ActionsWithoutTimeFrames: 'actions without time frames',
    ActionsWithoutDescription: 'actions without description',
    ActionsWithoutFocusArea: 'actions without focus area',
    ActionsWithoutFocusArea2: 'actions without focus area'
};
export function HealthIssuesFromJSON(json) {
    return HealthIssuesFromJSONTyped(json, false);
}
export function HealthIssuesFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HealthIssuesToJSON(value) {
    return value;
}
